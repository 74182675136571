import { request } from "../utils/request";
import { Axios, AxiosResponse } from "axios";
import { IProduct } from "./product";

export const getPromotionListApi = (
  column_value: string,
  page_num: number,
  per_page_num = 10,
  show_soft_deleted = false
): Promise<
  AxiosResponse<{
    data: Array<IProduct>;
    page_num: number;
    per_page_num: number;
    total_num: number;
    total_page_num: number;
    show_soft_deleted: number;
    order_by: null;
    filter_by: null;
    search_by: null;
  }>
> => {
  const data = {
    front_type: "web",
    column_value,
    pagination: {
      page_num,
      per_page_num,
      show_soft_deleted,
    },
  };
  return request.post("/promotions/pagination", data);
};
