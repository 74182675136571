import {request} from "../utils/request";
import {Axios, AxiosResponse} from "axios";

export interface IStudentListItem {
    student_id: string;
    info: string;
    phone: string;
}

export interface IStudentListRes {
    data: Array<IStudentListItem>;
    page_num: number;
    per_page_num: number;
    total_num: number;
    total_page_num: number;
    show_soft_deleted: boolean;
}

export const getStudentListApi = (page_num: number, per_page_num = 10, search_by = {
    keyword: '',
    phone: ''
}): Promise<AxiosResponse<IStudentListRes>> => {
    const data = {
        page_num,
        per_page_num,
        search_by
    }
    return request.post(`/students/pagination`, data)
}

export interface IStudentDetail {
    name: string
    birthday: string;
    sex: string;
    phone: string;
}
export const getStudentDetailApi = (student_id: string): Promise<AxiosResponse<IStudentDetail>> => {
    return request.get(`/students/id/${student_id}`)
}