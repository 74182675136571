import { useApp } from "../../context/app-context";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  DatePicker,
  DatePickerRef,
  Form,
  Input,
  Radio,
  Space,
} from "antd-mobile";

import "./signup-submit.scss";
import React, { RefObject, useEffect, useState } from "react";
import { getCourseDetailApi, ICourseDetailRes } from "../../api/course";
import dayjs, { Dayjs } from "dayjs";
import DropdownBox from "../../components/dropdown/Dropdown";
import { getPaymentMethodListApi, postPaymentApi } from "../../api/payment";
import { PhonebookOutline } from "antd-mobile-icons";
import { getStudentDetailApi } from "../../api/student";
import { showToast } from "../../utils/toast";
import { useActivate } from "react-activation";


export default function SignupSubmit() {
  const { startLoading, closeLoading } = useApp();
  const { course_id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [courseData, setCourseData] = useState<ICourseDetailRes>({
    title: "",
    info: "",
    content: {},
    fee: "",
  });
  const [amount, setAmount] = useState("");

  const [canSubmit, setCanSubmit] = useState(false);
  const [calculating, setCalculating] = useState(false);

  const [paymentList, setPaymentList] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);

  const [studentId, setStudentId] = useState("");

  const [form] = Form.useForm();

  const [formData, setFormData] = useState<{
    name: string;
    birthday: null | Date;
    sex: string;
    phone: string;
    // fee: string;
    discount: number;
    payment_method: string;
  }>({
    name: "",
    birthday: null,
    sex: "",
    phone: "",
    // fee: '',
    discount: 0,
    payment_method: "",
  });

  useActivate(() => {
    updateSelectStudentData()
  })
  

  const updateSelectStudentData = () => {
    if (sessionStorage.getItem("tempValue")) {
      const student = JSON.parse(sessionStorage.getItem("tempValue") || "{}");
      sessionStorage.removeItem("tempValue");
      if (student.student_id) {
        setStudentId(student.student_id);
        getStudentDetailApi(student.student_id).then((res) => {
          setFormData((formDtata) => {
            return {
              ...formData,
              name: res.data.name,
              birthday: res.data.birthday
                ? dayjs(res.data.birthday).toDate()
                : null,
              sex: res.data.sex,
              phone: res.data.phone,
              // fee: courseData.fee
            };
          });
          form.setFieldsValue({
            ...formData,
            name: res.data.name,
            birthday: res.data.birthday
              ? dayjs(res.data.birthday).toDate()
              : null,
            sex: res.data.sex,
            phone: res.data.phone,
            // fee: courseData.fee
          });
        });
      }
    }
  };

  useEffect(() => {
    updateSelectStudentData();

    // 获取付款方式
    getPaymentMethodListApi().then((res) => {
      let list: Array<{
        label: string;
        value: string;
      }> = [];
      Object.keys(res.data).forEach((item) => {
        list.push({
          label: item,
          value: res.data[item],
        });
      });
      setPaymentList(list);
    });
  }, []);

  useEffect(() => {
    // 獲取courseData
    getCourseDetailApi(course_id as string).then((res) => {
      setCourseData(res.data);
      if (res.data.fee) {
        setFormData((formData) => {
          return {
            ...formData,
            // fee: res.data.fee
          };
        });
        setAmount(res.data.fee);
      }
    });
  }, [course_id]);

  const updateMoney = (newDiscount: string) => {
    setCalculating(true);
    postPaymentApi(
      {
        course_id: course_id as string,
        student_id: studentId,
        new_student: {
          name: formData.name,
          birthday: formData.birthday
            ? dayjs(formData.birthday).format("YYYY-MM-DD")
            : "",
          sex: formData.sex,
          phone: formData.phone,
        },
        discount: newDiscount == null ? 1 : Number(newDiscount),
        payment_method: formData.payment_method,
      },
      "calculate"
    )
      .then((res) => {
        const amount = res.data.payment_amount;
        setAmount(amount);
      })
      .finally(() => {
        setCalculating(false);
      });
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields([
        "name",
        "birthday",
        "phone",
        "sex",
        "payment_method",
      ]);
    } catch (e) {
      return;
    }

    startLoading();
    try {
      await postPaymentApi(
        {
          course_id: course_id as string,
          student_id: studentId,
          new_student: {
            name: formData.name,
            birthday: formData.birthday
              ? dayjs(formData.birthday).format("YYYY-MM-DD")
              : "",
            sex: formData.sex,
            phone: formData.phone,
          },
          discount: formData.discount,
          payment_method: formData.payment_method,
        },
        "confirm"
      );
    } catch (e) {
      closeLoading();
      showToast("submit fail", "fail");
      return;
    }
    closeLoading();
    showToast("submit success", "success");
    navigate(-2);
  };
  useEffect(() => {
    if (
      formData.name &&
      studentId &&
      formData.discount != null &&
      formData.payment_method
    ) {
      setCanSubmit(true);
    }
  }, [formData.name, studentId, formData.discount, formData.payment_method]);

  const handleGoToStudentContact = () => {
    navigate("/student-contact");
  };

  return (
    <div className={"signup-submit-page bottom-block-page"}>
      <div className={"info-form"}>
        <div className={"title"}>{courseData.title}</div>
        <div className={"info"}>{courseData.info}</div>
        <div className={"form-item"}>
          <div className="label">{t("SignupSubmit.上課時間")}：</div>
          <div className="info-wrapper">{courseData.content["上課日期"]}</div>
        </div>
        <div className={"form-item"}>
          <div className="label">{t("SignupSubmit.開始時間")}：</div>
          <div className="info-wrapper">{courseData.content["開始時間"]}</div>
        </div>
        <div className={"form-item"}>
          <div className="label">{t("SignupSubmit.結束時間")}：</div>
          <div className="info-wrapper">{courseData.content["結束時間"]}</div>
        </div>
        <div className={"form-item"}>
          <div className="label">{t("SignupSubmit.上堂日")}：</div>
          <div className="info-wrapper">{courseData.content["星期"]}</div>
        </div>
        <div className={"form-item"}>
          <div className="label">{t("SignupSubmit.上堂數")}：</div>
          <div className="info-wrapper">{courseData.content["上堂數"]}</div>
        </div>
      </div>
      <div className={"border-block-line"}></div>

      <Form layout="horizontal" initialValues={formData} form={form}>
        <Form.Item
          className={"name-form-item"}
          name="name"
          extra={
            <PhonebookOutline onClick={() => handleGoToStudentContact()} />
          }
          label={t("SignupSubmit.學員姓名")}
          rules={[
            {
              required: true,
              message: t("SignupSubmit.rules.required") as string,
            },
          ]}
        >
          <Input placeholder="请输入姓名" />
        </Form.Item>
        <Form.Item
          name="birthday"
          label={t("SignupSubmit.出生日期")}
          trigger="onConfirm"
          rules={[
            {
              required: true,
              message: t("SignupSubmit.rules.required") as string,
            },
          ]}
          onClick={(e, datePickerRef: RefObject<DatePickerRef>) => {
            datePickerRef.current?.open();
          }}
        >
          <DatePicker>
            {(value) =>
              value
                ? dayjs(value).format("YYYY-MM-DD")
                : t("SignupSubmit.請輸入")
            }
          </DatePicker>
        </Form.Item>
        <Form.Item
          name="sex"
          label={t("SignupSubmit.性別")}
          rules={[
            {
              required: true,
              message: t("SignupSubmit.rules.required") as string,
            },
          ]}
        >
          <Radio.Group defaultValue="">
            <Space>
              <Radio value="M">{t("SignupSubmit.性別.男")}</Radio>
              <Radio value="F">{t("SignupSubmit.性別.女")}</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: t("SignupSubmit.rules.required") as string,
            },
          ]}
          label={t("SignupSubmit.電話號碼")}
        >
          <Input placeholder={t("SignupSubmit.請輸入") as string} />
        </Form.Item>
        <Form.Item name="name" label={t("SignupSubmit.付款金額")}>
          <span className={"text-info"}>{courseData.fee}</span>
        </Form.Item>

        <Form.Item
          className={"payment-form-item"}
          name="payment_method"
          rules={[
            {
              required: true,
              message: t("SignupSubmit.rules.required") as string,
            },
          ]}
          label={t("SignupSubmit.付款方式")}
        >
          <DropdownBox
            defaultValue={formData.payment_method}
            datasource={paymentList}
            placeholder={t("CommonComponent.placeholder") as string}
            onChange={(newVal: string | number) => {
              setFormData((formData) => {
                return {
                  ...formData,
                  payment_method: newVal as string,
                };
              });
            }}
          ></DropdownBox>
        </Form.Item>
        <Form.Item
          name="discount"
          rules={[
            {
              required: true,
              message: t("SignupSubmit.rules.required") as string,
            },
          ]}
          label={t("SignupSubmit.優惠折扣")}
        >
          <Input
            onChange={(newVal: string) => updateMoney(newVal)}
            type={"number"}
            max={1}
            min={0}
            placeholder={t("SignupSubmit.請輸入") as string}
          />
        </Form.Item>
      </Form>

      <div className={"bottom-absolute-block"}>
        <div className={"border-block-line"}></div>
        <div className={"money-submit"}>
          <div className={"money-wrapper"}>
            {t("SignupSubmit.應付金額")}: <span>${amount}</span>
          </div>
          <Button
            className={"submit-button"}
            onClick={handleSubmit}
            color={"primary"}
            disabled={!canSubmit || calculating}
          >
            {t("CommonComponent.submit")}
          </Button>
        </div>
      </div>
    </div>
  );
}
