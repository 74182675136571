import { InfiniteScroll, List, Tabs, Image } from "antd-mobile";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ICommonColumn,
  getProductTypeApi,
  IProduct,
  getProductListApi,
} from "../../api/product";
import { showToast } from "../../utils/toast";
import LA01 from "./LA01";
import LB01 from "./LB01";

import "./product.scss";
import "./list.scss";

export default function Product() {
  const { t } = useTranslation();
  const [productTypes, setProductTypes] = useState<Array<ICommonColumn>>([]);
  const [activeType, setActiveType] = useState("");
  const [list, setList] = useState<Array<IProduct>>([]);
  const [doubleList, setDoubleList] = useState<Array<[IProduct, IProduct]>>([]);
  const [pageNum, setPageNum] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getProductTypeApi().then((res) => {
      setProductTypes(res.data.columns || []);
      if (res.data.columns.length) {
        setActiveType(res.data.columns[0].column_value);
      }
    });
  }, []);

  useEffect(() => {
    if (activeType) {
      listRef.current?.scrollTo(0, 0);
      reload();
    }
  }, [activeType]);

  const loadMore = async (pageNum: number, reload?: boolean) => {
    try {
      const data = await getProductListApi(activeType, reload ? 0 : pageNum);
      const newList = reload
        ? data.data.data
        : list.concat(data.data.data || []);
      setList(newList);
      // const newDoubleList: Array<[IProduct, IProduct | null]> = [];
      // for (let i = 0; i < newList.length; i++) {
      //   if (i % 2 === 0) {
      //     newDoubleList.push([newList[i], null]);
      //   } else {
      //     let last = newDoubleList[newDoubleList.length - 1];
      //     last = [last[0], newList[i]];
      //     newDoubleList[newDoubleList.length - 1] = last;
      //   }
      // }
      // setDoubleList(newDoubleList as Array<[IProduct, IProduct]>);
      setPageNum(data.data.page_num);
      setHasMore(data.data.total_page_num > pageNum);
    } catch (e) {
      showToast("load data error", "fail");
      if (pageNum === 1) {
        setHasMore(false);
      }
    }
  };

  const reload = () => {
    setList([]);
    setPageNum(0);
    setHasMore(false);
    loadMore(0, true);
  };

  return (
    <div className="product-page common-list">
      <Tabs
        className="tabs"
        activeKey={activeType}
        onChange={(newType: string) => setActiveType(newType)}
      >
        {productTypes.map((type) => {
          return (
            <Tabs.Tab
              title={type.column_name}
              key={type.column_value}
            ></Tabs.Tab>
          );
        })}
      </Tabs>
      <div className={"list-wrapper"} ref={listRef}>
        <div className="list">
          {list.map((item, index) => {
            if (item.display_style === 'LA-01') {
              return <LA01 key={item.product_id} item={item}></LA01>;
            } else if (item.display_style === 'LB-01') {
              return (
                <LB01 key={item.product_id} item={item}></LB01>
                // <>
                // <LB01 key={item.product_id} item={item}></LB01>
                // <LB01 key={item.product_id} item={item}></LB01>
                // </>
              )
            }
          })}
        </div>
        {/* <List className="list">
          {doubleList.map((item, index) => {
            return (
              <List.Item key={item[0].product_id} className="list-item">
                <div className="list-item-half">
                  <Image className="image" src={item[0].pic_url}></Image>
                  <div className="title">{item[0].name}</div>
                  <div className="money">
                    <span className="currency">{item[0].currency}</span>
                    {item[0].unit_amount}
                  </div>
                </div>
                {item[1] && (
                  <div className="list-item-half">
                    <Image className="image" src={item[1].pic_url}></Image>
                    <div className="title">{item[1].name}</div>
                    <div className="money">
                      <span className="currency">{item[1].currency}</span>
                      {item[1].unit_amount}
                    </div>
                  </div>
                )}
              </List.Item>
            );
          })}
        </List> */}
        <InfiniteScroll
          loadMore={() => loadMore(pageNum + 1)}
          hasMore={hasMore}
        />
      </div>
    </div>
  );
}
