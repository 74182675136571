import React, {FC, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import DropdownBox, {IDropdownOption} from "../../components/dropdown/Dropdown";
import {useApp} from "../../context/app-context";
import {
    getCoachListApi,
    getGroupDetailApi,
    getGroupingTimeListApi,
    IGroupDetailRes,
    patchGroupStudentsApi
} from "../../api/grouping";
import {Button, Checkbox, List, Radio, Space} from "antd-mobile";
import {cloneDeep} from 'lodash-es'
import {showConfirm} from "../../hooks/useConfirm";
import {showToast, showToastDelay} from "../../utils/toast";

const GroupingDetail: FC = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {address, date, groupDetailId, time: defaultTime, coaches: defaultCoaches} = useParams();
    const [coachesOptions, setCoachesOptions] = useState<Array<IDropdownOption>>([])
    const [coaches, setCoaches] = useState<string>(defaultCoaches as string)
    const [timeList, setTimeList] = useState<Array<IDropdownOption>>([])
    const [time, setTime] = useState<string>(defaultTime as string)
    const [editStatus, setEditStatus] = useState(false)
    const [checkedMap, setCheckedMap] = useState<Record<string, boolean>>({})

    const [groupDetail, setGroupDetail] = useState<IGroupDetailRes>({
        coach: '',
        date: '',
        weekday: '',
        swimming_pool: '',
        student_num: 0,
        ungrouped_student_num: 0,
        grouped_students: [],
        ungrouped_students: []
    })

    const {startLoading, closeLoading} = useApp();

    const handleCoachesChange = (newVal: string | number) => {
        setCoaches(newVal as string)
    }

    const handleTimeChange = (newVal: string | number) => {
        setTime(newVal as string)
    }

    const handleCheckboxChange = (newVal: boolean, item: {
        roll_call_id: string
        student_info: string
    }) => {
        const currentMap = cloneDeep(checkedMap)
        if (newVal) {
            currentMap[item.roll_call_id] = true
            setCheckedMap(currentMap)
        } else {
            delete currentMap[item.roll_call_id]
            setCheckedMap(currentMap)
        }
    }

    const handleEditBtn = async () => {
        let confirmType = 'course'
        const handleConfirmType = (newType: any) => {
            confirmType = newType
        }
        // 編輯狀態，是確認
        if (editStatus) {
            // 需要保存，然後保存完需要刷新數據
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { status, setClose } = await showConfirm(<div className={'grouping-page-submit-confirm'}>
                <Radio.Group defaultValue={confirmType} onChange={handleConfirmType}>
                    <Space direction={'vertical'}>
                        <Radio value={'course'}>{t('Grouping.Detail.整個課程')}</Radio>
                        <Radio value={'day'}>{t('Grouping.Detail.當天')}</Radio>
                    </Space>
                </Radio.Group>
            </div>)
            if (status === true) {
                // 提交
                const students = []
                for (let key in checkedMap) {
                    if (checkedMap[key]) {
                        students.push(key)
                    }
                }
                startLoading()
                try {
                    let res = await patchGroupStudentsApi(confirmType, {
                        roll_call_ids: students,
                        group_detail_id: groupDetailId as string
                    })
                    setClose();

                    showToastDelay('Success', 'success')
                    // 需要刷新
                    getGroupDetailApi(groupDetailId as string).then((res) => {
                        setGroupDetail(res.data)
                        closeLoading()
                    })
                } catch (e){
                    showToast('Error', 'fail')
                } finally {
                    closeLoading()
                }


            } else {
                setClose()
            }
            setCheckedMap({})
            setEditStatus((false))
        } else {
            // 打開編輯
            setEditStatus(true)
            const selectedMap: Record<string, boolean> = {}
            groupDetail.grouped_students.forEach(student => {
                selectedMap[student.roll_call_id] = true
            })
            setCheckedMap(selectedMap)
        }
    }

    const updateCoachesList = async (selectedTime: string) => {
        const res = await getCoachListApi(address as string, date as string, selectedTime, 'GROUP')
        const coachesList = Object.keys(res.data).map(key => {
            return {
                label: key,
                value: res.data[key]
            }
        })
        setCoachesOptions(coachesList)
        if (coachesList.length) {
            setCoaches(coachesList[0].value)
        }
    }

    // 搜索條件變化後需要調用
    useEffect(() => {
        if (time && coaches) {
            startLoading()
            getGroupDetailApi(groupDetailId as string).then((res) => {
                setGroupDetail(res.data)
                closeLoading()
            })
        }
    }, [coaches, time])

    // mount
    useEffect(() => {
        startLoading()
        // 獲取時間列表
        getGroupingTimeListApi(date as string, address as string).then(res => {
            const times = res.data.map(item => {
                return {
                    label: item,
                    value: item
                }
            })
            setTimeList(times)
            closeLoading()
        })

        updateCoachesList(time)
    }, [])


    return <div className={'groupingDetail-page grouping-page  schedule-rollcall-page bottom-block-page'}>
        <div className={'filter-container'}>
            <DropdownBox datasource={timeList} placeholder={t('CommonComponent.placeholder') as string}
                         defaultValue={time}
                         onChange={handleTimeChange}></DropdownBox>
            <DropdownBox datasource={coachesOptions} placeholder={t('CommonComponent.placeholder') as string}
                         defaultValue={coaches} onChange={handleCoachesChange}></DropdownBox>
        </div>
        <div className={'info-form'}>
            <div className="form-item">
                <div className="label">{t('Grouping.Detail.日期')}：</div>
                <div className="info-wrapper">{groupDetail.date || ''}</div>
            </div>
            <div className="form-item">
                <div className="label">{t('Grouping.Detail.星期')}：</div>
                <div className="info-wrapper">{groupDetail.weekday}</div>
            </div>
            <div className="form-item">
                <div className="label">{t('Grouping.Detail.泳池')}：</div>
                <div className="info-wrapper">{groupDetail.swimming_pool}</div>
            </div>
        </div>
        <div className={'border-block-line'}></div>
        {/*<div>*/}
        {/*    {JSON.stringify((checkedMap))}*/}
        {/*</div>*/}
        <List className={'list-with-header'} header={t('Grouping.Detail.分組成員')}>
            {
                groupDetail.grouped_students.map((item, index) => {
                    return <List.Item key={item.roll_call_id}>
                        <div className={'content'}>{item.student_info}</div>
                        {
                            editStatus ? <Checkbox checked={checkedMap[item.roll_call_id]} onChange={(checked) => handleCheckboxChange(checked, item)} className={'right-wrapper'}></Checkbox> : null
                        }
                    </List.Item>
                })
            }
        </List>
        <div className={'border-block-line'}></div>
        <List className={'list-with-header'} header={t('Grouping.Detail.未分組')}>
            {
                groupDetail.ungrouped_students.map((item, index) => {
                    return <List.Item key={item.roll_call_id}>
                        <div className={'content'}>{item.student_info}</div>
                        {
                            editStatus ? <Checkbox checked={checkedMap[item.roll_call_id]} onChange={(checked) => handleCheckboxChange(checked, item)} className={'right-wrapper'}></Checkbox> : null
                        }

                    </List.Item>
                })
            }
        </List>
        <div className={'bottom-absolute-block'} style={{padding: '12px'}}>
            <Button className={'primary-button-bg'} color={'primary'}
                    block={true} onClick={handleEditBtn}>{editStatus ? t('Grouping.Detail.確認') : t('Grouping.Detail.編輯分組')}</Button>
        </div>
    </div>
}

export default GroupingDetail