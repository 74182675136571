import {Button, Collapse} from "antd-mobile";
import {DownOutline, UpOutline} from 'antd-mobile-icons'
import './collapselist.css'
import {IRollCallItem, IRollCallsCourseDetail} from "../../api/schedule";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import whatappImg from '../../assets/rollcall/WhatsApp.svg'
import {useNavigate} from "react-router";

export default function CollpaseList(props: {
    detailData: IRollCallsCourseDetail,
    onStatusChange: (newValue: string, attrPath: string) => void
}) {
    // const activeKeys = []
    const [activeKeys, setActiveKeys] = useState<Array<string>>([])
    const {t} = useTranslation()

    useEffect(() => {
        setActiveKeys(props.detailData.courses.map((course,index) => {
            return index + ''
        }))
    }, [props.detailData.courses])

    const handleCollapseItemActiveChange = (keys: string[]) => {
        setActiveKeys(keys)
    }

    return <Collapse className={'schedule-rollcall-root-collapse'} defaultActiveKey={['root']}>

        <Collapse.Panel key={'root'} title={<CollpaseRootTitle title={props.detailData.roll_call_summary}/>}
                        arrow={active => <CollapseArrow active={active} activeTitle={t('ScheduleRollCall.收起所有課程')}
                                                        unActiveTitle={t('ScheduleRollCall.展開所有課程')}/>}>
            <Collapse className={'schedule-rollcall-item-collpase'} activeKey={activeKeys} onChange={handleCollapseItemActiveChange}>
                {
                    props.detailData.courses.map((course, index) => {
                        return <Collapse.Panel key={index + ''} title={<CollapseItemTitle title={course.course_info}/>}
                                               arrow={active => <CollapseArrow active={active} activeTitle={t('ScheduleRollCall.收起')}
                                                                               unActiveTitle={t('ScheduleRollCall.展開')}/>}>
                            {
                                course.roll_calls.map((roll_call, rollIndex) => {
                                    return <Item item={roll_call} key={roll_call.roll_call_id}
                                                 statusDict={props.detailData.roll_call_status_dict}
                                                 onChange={(newStatus) => props.onStatusChange(newStatus, `courses.${index}.roll_calls.${rollIndex}.status_code`)}></Item>
                                })
                            }

                        </Collapse.Panel>
                    })
                }
                {/*<Collapse.Panel key={'1'} title={<CollapseItemTitle/>}*/}
                {/*                arrow={active => <CollapseArrow active={active} activeTitle={'收起'} unActiveTitle={'展開'} />}>*/}
                {/*    <Item></Item>*/}
                {/*    <Item></Item>*/}
                {/*    <Item></Item>*/}
                {/*    <Item></Item>*/}
                {/*    <Item></Item>*/}
                {/*</Collapse.Panel>*/}
                {/*<Collapse.Panel key={'2'} title={<CollapseItemTitle/>}*/}
                {/*                arrow={active => <CollapseArrow active={active} activeTitle={'收起'} unActiveTitle={'展開'}/>}>*/}
                {/*    <Item></Item>*/}
                {/*    <Item></Item>*/}
                {/*    <Item></Item>*/}
                {/*    <Item></Item>*/}
                {/*    <Item></Item>*/}
                {/*</Collapse.Panel>*/}
            </Collapse>

        </Collapse.Panel>
    </Collapse>
}

function CollpaseRootTitle(props: {
    title: string
}) {
    return <div className={'collapse-root-title'}>
        <span
            className={'adm-button adm-button-primary adm-button-fill-none adm-button-shape-default'}>{props.title}
        </span>
    </div>
}

function CollapseArrow(props: {
    active: boolean,
    activeTitle: string
    unActiveTitle: string
}) {
    return <div>
        <span
            className={'adm-button adm-button-primary adm-button-fill-none adm-button-shape-default'}>{props.active ? props.activeTitle : props.unActiveTitle}</span> {props.active ?
        <UpOutline></UpOutline> : <DownOutline></DownOutline>}
    </div>
}

function CollapseItemTitle(props: {
    title: string
}) {
    return <div className={'collapse-item-title'}>
        <span>{props.title}</span>
    </div>
}

function Item(props: {
    item: IRollCallItem,
    statusDict: Record<string, string>
    onChange: (newValue: string) => void
}) {
    const nav = useNavigate()
    const handleWhatapp = (item: IRollCallItem) => {
        if (item.whatsapp) {
            window.open(item.whatsapp)
        }
    }
    return <div className={'item'}>
        <span className={'title'}>{props.item.student_info}
            {
                props.item.whatsapp && <img onClick={() => handleWhatapp(props.item)} src={whatappImg} alt=""/>
            }</span>
        <div className={'right-wrapper'}>
            <Button color={props.item.status_code === 'ATT' ? "primary" : 'default'} size={"small"}
                    data-active-key={'ATT'} onClick={() => props.onChange(props.item.status_code === 'ATT' ? 'NA' : 'ATT')}>{props.statusDict['ATT']}</Button>
            <Button color={props.item.status_code === 'ABS' ? "danger" : 'default'} size={"small"}
                    data-active-key={'ABS'} onClick={() => props.onChange(props.item.status_code === 'ABS' ? 'NA' : 'ABS')}>{props.statusDict['ABS']}</Button>
            <Button color={props.item.status_code === 'PL' ? "success" : 'default'} size={"small"}
                    data-active-key={'PL'} onClick={() => props.onChange(props.item.status_code === 'PL' ? 'NA' : 'PL')}>{props.statusDict['PL']}</Button>
            <Button color={props.item.status_code === 'IND' ? "warning" : 'default'} size={"small"}
                    data-active-key={'IND'} onClick={() => props.onChange(props.item.status_code === 'IND' ? 'NA' : 'IND')}>{props.statusDict['IND']}</Button>
        </div>
    </div>
}