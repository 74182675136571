import { Toast} from "antd-mobile";

export const showToast = (message: string, status?: 'success' | 'fail') => {
    Toast.show({
        icon: status,
        content: message,
        maskClickable: false
    })
}
export const showToastDelay = (message: string, status?: 'success' | 'fail', delay = 200) => {
    setTimeout(() => {
        showToast(message, status)
    }, delay)
}