import React, {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {Button, List} from "antd-mobile";
import DropdownBox, {IDropdownOption} from "../../components/dropdown/Dropdown";
import {createGroupApi, getCoachListApi, getCourseListApi} from "../../api/grouping";
import {useApp} from "../../context/app-context";
import {showToast} from "../../utils/toast";

const AddGroup: FC = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {startLoading, closeLoading} = useApp();
    const {mode = '', date = '', time = '', address = ''} = useParams();

    const [courses, setCourses] = useState<Array<IDropdownOption>>([])
    const [course, setCourse] = useState('')

    const [coaches, setCoaches] = useState<Array<IDropdownOption>>([])
    const [coach, setCoach] = useState('')

    const handleCourseChange = (newVal: string | number) => {
        setCourse(newVal as string)
        updateCoaches('AVAILABLE', newVal as string)
    }

    const handleCoachChange = (newVal: string | number) => {
        setCoach(newVal as string)
    }

    const handleAddGroup = () => {

        startLoading()
        createGroupApi(coach, mode === 'UNIQUE_DATE' ? 'COURSE' : 'DAY', mode === 'UNIQUE_DATE' ? course : {
            address_code: address,
            date,
            time_range: time
        }).then(res => {
            // 提示
            showToast(res.data.detail, 'success')
            // 回到上一个页面
            navigate(-1)

        }).catch((e) => {
            showToast('Error', 'fail')
        }).finally(() => {
            closeLoading()
        })
    }

    const updateCoaches = (mode: string, course_id?: string) => {
        startLoading()
        getCoachListApi(address, course_id ? '' : date, course_id ? '' : time, mode, course_id).then(res => {
            let options: Array<IDropdownOption> = []
            for (let key in res.data) {
                options.push({
                    value: res.data[key],
                    label: key
                })
            }
            setCoaches(options)
        }).finally(() => {
            closeLoading()
        })
    }


    useEffect(() => {
        startLoading()
        if (mode === "UNIQUE_DATE") {
            getCourseListApi(mode, address, date, time).then(res => {
                if (res.data) {
                    let options: Array<IDropdownOption> = []
                    for (let key in res.data) {
                        options.push({
                            value: res.data[key],
                            label: key
                        })
                    }
                    setCourses(options)
                }
            }).finally(() => {
                closeLoading()
            })
        } else {
            // 当天
            updateCoaches("AVAILABLE")
        }


    }, [])

    return <div className={'add-group-page bottom-block-page'}>
        <div className={'info-form'}>
            <div className="form-item">
                <div className="label">{t('AddGroup.日期')}：</div>
                <div className="info-wrapper">{date || ''}</div>
            </div>
            <div className="form-item">
                <div className="label">{t('AddGroup.時間')}：</div>
                <div className="info-wrapper">{time || ''}</div>
            </div>
        </div>
        <div className={'border-block-line'}></div>
        <List>
            {
                mode === 'UNIQUE_DATE' ? <List.Item>
                    <div className="content">選擇課程</div>
                    <DropdownBox datasource={courses} placeholder={t('CommonComponent.placeholder') as string}
                                 defaultValue={course}
                                 onChange={handleCourseChange}></DropdownBox>
                </List.Item> : null
            }

            <List.Item>
                <div className="content">選擇教練</div>
                <DropdownBox datasource={coaches} placeholder={t('CommonComponent.placeholder') as string}
                             defaultValue={coach}
                             onChange={handleCoachChange}></DropdownBox>
            </List.Item>
        </List>
        <div style={{padding: '12px'}} className={'bottom-absolute-block'}>
            <Button className={'primary-button-bg'} color={'primary'}
                    block={true} disabled={!coach} onClick={handleAddGroup}>{t('Grouping.Detail.新建分組')}</Button>
        </div>
    </div>
}

export default AddGroup