import React, {useEffect, useState} from 'react';
import {Route, Routes, Navigate, useNavigate} from "react-router";
import './App.css';
import Home from "./views/home/Home";
import Login from "./views/login/Login";
import {useLoading} from "./hooks/loading";
import Loading from './components/loading/Loading'
import {useLocation} from "react-router-dom";
import {initTagManager, updatePageView} from "./utils/tagmanager";
import TitlePage from "./views/title-page/TitlePage";
import Setting from "./views/setting/Setting";
import {useTranslation} from "react-i18next";
import Schedule from "./views/schedule/Schedule";
import ScheduleRollCall from "./views/schedule-rollcall/ScheduleRollCall";
import KeepAlive from 'react-activation'
import Grouping from "./views/grouping/Grouping";
import GroupingSelect from "./views/grouping/GroupingSelect";
import GroupingDetail from "./views/grouping/GroupingDetail";
import noticeImg from './assets/main/notice.png'
import AddGroup from "./views/grouping/AddGroup";
import SaleOrder from "./views/./sales-order/SaleOrder";
import SignupSubmit from "./views/signup-submit/SignupSubmit";
import StudentContact from "./views/student-contact/StudentContact";
import Product from './views/product/Product';


function App() {
    const {loading} = useLoading()

    const {t} = useTranslation()

    const nav = useNavigate();
    const location = useLocation();

    const [homeTitle, setHomeTitle] = useState('Main.title')
    const [isMePage, setIsMePage ] = useState(location.pathname === '/me')

    
    initTagManager();

    useEffect(() => {
        if (location.pathname === '/me') {
            setIsMePage(true)
        } else {
            setIsMePage(false)
        }
        if (location.pathname === '/product') {
            setHomeTitle('Product.title')
        } else {
            setHomeTitle('Main.title')
        }
        updatePageView({
            path: location.pathname as string,
            title: ''
        })

    }, [location])


    return (
        <div className="App">
            <Loading loading={loading}/>
            <Routes>
                {/*<Route path={"/"}   element={<Navigate to={'/home'}/>}></Route>*/}
                <Route path={"/login"} element={<Login/>}></Route>
                <Route path={"/setting"}
                       element={<TitlePage title={t("Setting.title")} children={<Setting/>}/>}></Route>

                <Route path={"/schedule"} element={<TitlePage title={t("Schedule.title")}
                                                              children={<KeepAlive name={'schedule'}
                                                                                   when={true}><Schedule/></KeepAlive>}/>}></Route>
                <Route path={"/schedule-rollcall/:address/:date/:beginTime"}
                       element={<TitlePage title={t("ScheduleRollCall.title")}
                                           children={<ScheduleRollCall/>}/>}></Route>
                <Route path={"/grouping/"}
                       element={<TitlePage title={t("Grouping.title")}
                                           children={<KeepAlive name={'grouping'}
                                                                when={true}><Grouping/></KeepAlive>}/>}></Route>
                <Route path={"/grouping/:address/:date"}
                       element={<TitlePage title={t("Grouping.title")}
                                           children={<GroupingSelect/>}/>}></Route>
                <Route path={"/grouping/:groupDetailId/:address/:coaches/:date/:time"}
                       element={<TitlePage title={t("Grouping.Detail.分組詳情")}
                                           children={<GroupingDetail/>}/>}></Route>
                <Route path={"/addgroup/:mode/:address/:date/:time"}
                       element={<TitlePage title={t("AddGroup.title")}
                                           children={<AddGroup/>}/>}></Route>
                {/*销售单*/}
                <Route path={"/sales-order"}
                       element={<TitlePage title={t('SalesOrder.title')} children={<SaleOrder/>}/>}></Route>
                {/*点名*/}
                <Route path={"/signup-select"} element={<TitlePage title={t("SignupSelect.title")}
                                                              children={<KeepAlive name={'schedule'}
                                                                                   when={true}><Schedule/></KeepAlive>}/>}></Route>
                {/*报名提交*/}
                <Route path={"/signup-submit/:course_id"} element={<TitlePage title={t("SignupSubmit.title")}
                                                                  children={<KeepAlive name={'schedule'}
                                                                                       when={true}><SignupSubmit/></KeepAlive>}/>}></Route>
                {/*学生通讯录*/}
                <Route path={"/student-contact"}
                       element={<TitlePage title={t("StudentContact.title")}
                                           children={<StudentContact onItemClick={(item) => {
                                               // 传参
                                               sessionStorage.setItem('tempValue', JSON.stringify(item))
                                               nav(-1)
                                            //    setTimeout(() => {
                                            //        if (process.env.NODE_ENV !== 'development') {
                                            //            sessionStorage.removeItem('tempValue')
                                            //        }
                                            //    }, 1000)
                                           }
                                           }/>}/>}></Route>
{/*                     
                    <Route path={"/product"}
                       element={<TitlePage title={t("Product.title")} hideNav={true}
                                           children={<Home/>} />}></Route> */}
                {/* <Route path={"/"}
                       element={<TitlePage title={t(homeTitle)} hideNav={true}
                                           children={<Home/>}
                                           right={<img className={'nav-right'} src={noticeImg}/>}/>}></Route> */}
                <Route path={"*"}
                       element={<TitlePage hidden={isMePage} title={t(homeTitle)} hideNav={true}
                                           children={<Home/>}
                                           right={<img className={'nav-right'} src={noticeImg}/>}/>}></Route>
                {/* <Route path={"*"} element={<Home/>}></Route> */}

            </Routes>

        </div>
    );
}

export default App;
