import { IProduct } from "../../api/product";
import { InfiniteScroll, List, Tabs, Image } from "antd-mobile";

export default function LA01(props: { item: IProduct }) {
    const { item } = props
  return (
    <div className="list-item list-item-half" key={item.product_id}>
      <Image className="image" src={item.pic_url}></Image>
      <div className="title">{item.name}</div>
      <div className="money">
        <span className="currency">{item.currency}</span>
        {item.unit_amount}
      </div>
    </div>
  );
}
