import homeImg from "../../assets/tab/home.svg";
import homeActiveImg from "../../assets/tab/home-active.svg";
import courseImg from "../../assets/tab/course.svg";
import courseActiveImg from "../../assets/tab/course-active.svg";
import productImg from "../../assets/tab/product.svg";
import productActiveImg from "../../assets/tab/product-active.svg";
import meImg from "../../assets/tab/me.svg";
import meActiveImg from "../../assets/tab/me-active.svg";

import React from "react";
import {TabBar} from "antd-mobile";
import {useNavigate, useLocation} from "react-router-dom"
import {useTranslation} from "react-i18next";

export default function BottomTab() {

    const navigate = useNavigate()
    const location = useLocation()

    const { t } = useTranslation()

    const { pathname } = location

    const setRouteActive = (value: string) => {
        navigate(value)
    }

    const tabs = [{
        key: '',
        title: t('Main.bottomTab.mainpage'),
        icon: <img src={pathname === '/' ? homeActiveImg : homeImg} alt=""/>
    }, {
        key: 'course',
        title: t('Main.bottomTab.course'),
        icon: <img src={pathname === '/course' ? courseActiveImg : courseImg} alt=""/>
    }, {
        key: 'product',
        title: t('Main.bottomTab.product'),
        icon: <img src={pathname === '/product' ? productActiveImg : productImg} alt=""/>
    }, {
        key: 'me',
        title: t('Main.bottomTab.me'),
        icon: <img src={pathname === '/me' ? meActiveImg : meImg} alt=""/>
    }]


    return (
        <TabBar data-path={pathname} className={"bottom-tabbar"} activeKey={pathname} onChange={value => setRouteActive(value)}>
            {tabs.map(item => (
                <TabBar.Item key={item.key} icon={item.icon} title={item.title}/>
            ))}
        </TabBar>
    )
}