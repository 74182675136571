import React, {useState} from 'react';
import {Button, List, Switch} from "antd-mobile";
import noticeImg from '../../assets/me/notice.svg'
import './setting.css'
import {useApp} from "../../context/app-context";
import {updateLangApi} from "../../api/setting";
import {updateLang} from "../../utils/user";
import {useNavigate} from "react-router";
import i18n from '../../utils/i18n'
import {useTranslation} from "react-i18next";
import {useAliveController} from "react-activation";


function LabelAndIcon(props: {
    label: string,
    icon: string
}) {
    return <div className={'label-container'}>
        <img src={props.icon} alt=""/>
        <span>{props.label}</span>
    </div>
}

export default function Setting() {
    const navigate = useNavigate();



    const { t } = useTranslation()

    const [lang, setLang] = useState(useApp().user?.lang?.toLowerCase() || 'cn')

    const handleLangChange = async (checked: boolean) => {
        let newLang = lang
        if (checked) {
            newLang = 'en'
        } else {
            newLang = 'cn'
        }
        // 提交接口
        await updateLangApi(newLang)
        updateLang(newLang)
        setLang(newLang)
        i18n.changeLanguage(newLang)
    }

    const handleLogout = () => {
        navigate('/login', {
            replace: true
        })
    }



    return <div className={'setting-page'}>
        <List className={"setting-list"}>
            <List.Item extra={<Switch/>}>{t('Setting.menu.消息通知')}</List.Item>
            <List.Item
                extra={<Switch checked={lang === 'en'} onChange={(checked) => handleLangChange(checked)}
                               uncheckedText='CN' checkedText='EN'/>}>{t('Setting.menu.切換語言')}</List.Item>
        </List>
        <div className={'setting-bottom'}>
            <Button block color='danger' onClick={handleLogout}>{t('Setting.logout')}</Button>
        </div>
    </div>
}