import { request } from "../utils/request";
import { Axios, AxiosResponse } from "axios";

export interface ICarouselItem {
  pic_url: string;
  title: string;
  subtitle: string;
  can_redirect: boolean;
  open_new_tab: boolean;
  redirect_url: string;
}

export const getCarouselListApi = (): Promise<
  AxiosResponse<ICarouselItem[]>
> => {
  return request.get(`/carousels/home`);
};
