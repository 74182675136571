import {FC, useState} from 'react';
import {InfiniteScroll, List, Button} from 'antd-mobile'
import './sales-order.css';
import {getSalesListApi, ISalesListItem} from "../../api/sales";
import {showToast} from "../../utils/toast";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";

const SaleOrder: FC = () => {
    const nav = useNavigate();
    const {t}= useTranslation();
    const [list, setList] = useState<Array<ISalesListItem>>([])
    const [pageNum, setPageNum] = useState(0)
    const [hasMore, setHasMore] = useState(true)

    const loadMore = async (pageNum: number) => {
        try {
            const data = await getSalesListApi(pageNum)

            setList(list.concat(data.data.data || []))
            setPageNum(data.data.page_num)
            setHasMore(data.data.total_page_num > pageNum)
        } catch (e) {
            showToast('load data error', 'fail')
        }
    }

    const handleAdd = () => {
        nav('/signup-select')
    }


    return <div className={'sales-order-page bottom-block-page'}>
        <div className={'list-wrapper'}>
            <List className={'order-list'}>
                {
                    list.map((item, index) => {
                        return <List.Item key={index} className={'list-item'}>
                            <div>
                                <div className={'item-row'}>
                                    <div className={'name left'}>{item.title}</div>
                                    <div className={'status right'}
                                         style={{color: !item.is_paid ? '#E33B3B' : ''}}>{item.payment_status}</div>
                                </div>
                                {
                                    Object.keys(item.content).map(key => {
                                        return <div className={'item-row'} key={key}>
                                            <div className={'left'}>{key}</div>
                                            <div className={'right'}>{item.content[key]}</div>
                                        </div>
                                    })
                                }
                            </div>
                        </List.Item>
                    })
                }
            </List>
            <InfiniteScroll loadMore={() => loadMore(pageNum + 1)} hasMore={hasMore}/> 
        </div>

        <div style={{padding: '12px'}} className={'bottom-absolute-block'}>
            <Button className={'primary-button-bg'} color={'primary'} block={true} onClick={handleAdd}>{t('SalesOrder.新增')}</Button>
        </div>
    </div>
}

    export default SaleOrder