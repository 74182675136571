import DropdownBox, {IDropdownOption} from "../../components/dropdown/Dropdown";
import {useEffect, useState} from "react";
import {
    getRollCallsCourseDetailApi,
    getRollCallsTimeListApi,
    getSwimsListApi,
    IRollCallsCourseDetail, patchRollCallsApi
} from "../../api/schedule";
import {useApp} from "../../context/app-context";
import {useTranslation} from "react-i18next";
import CollapseList from './CollpaseList'
import './schedule-rollcall.css'
import { Button } from "antd-mobile";
import {useLocation, useParams} from "react-router-dom";
import { update, cloneDeep } from 'lodash-es'
import {showToast} from "../../utils/toast";

// http://localhost:3000/#/schedule-rollcall/MS/2023-03-05/10:30

export default function ScheduleRollCall() {
    const { t } = useTranslation()
    const {address, beginTime, date} = useParams();
    const [swinsOptions, setSwinsOptions] = useState<Array<IDropdownOption>>([])
    const [swinAddress, setSwinAddress] = useState(address as string);
    const [timeList, setTimeList] = useState<Array<IDropdownOption>>([])

    const [detailData, setDetailData] = useState<IRollCallsCourseDetail>({
        begin_time: '',
        end_times: '',
        weekday: '',
        coaches: '',
        roll_call_summary: '',
        roll_call_status_dict: {},
        courses: []
    })

    const [canRollCall, setCanRollCall] = useState(false)


    const {startLoading, closeLoading} = useApp();


    const [time, setTime] = useState(beginTime as string)

    const handleCourseTypeChange = (newVal: string | number) => {
        // 设置新的游泳地址
        setSwinAddress(newVal as string)
        // 需要更新选择时间段列表
        updateTimeList(newVal as string)
    }

    const handleTimeChange = (newVal: string | number) => {
        // 更新时间
        setTime(newVal as string)
        // 重新查看详情
        updateDetail(swinAddress, newVal as string)
    }

    const handleStatusChange = (newStatus: string, attrPath: string) => {
        const newDetailData = cloneDeep(detailData)
        update(newDetailData, attrPath, () => newStatus)
        setDetailData(newDetailData)
    }

    const handlePatchSubmit = async () => {
        startLoading()
        const datas: Array<{
            roll_call_id: string
            status_code: string
        }> = []
        detailData.courses.forEach(course => {
            course.roll_calls.forEach(item => {
                datas.push({
                    roll_call_id: item.roll_call_id,
                    status_code: item.status_code
                })
            })
        })
        try {
            let res = await patchRollCallsApi(datas)
            closeLoading();
            setTimeout(() => {
                // 提示
                showToast(res.data, 'success')
                // 重新请求数据
                updateDetail(swinAddress, time)
            }, 200)


        } finally {
            closeLoading();
        }
    }

    const updateTimeList = async (address: string) => {
        const res = await getRollCallsTimeListApi(date as string, address as string)
        const times = res.data.map(item => {
            return {
                label: item,
                value: item
            }
        })
        setTimeList(times)
    }

    const updateDetail = async (address: string, begin_time: string) => {
        startLoading();
        const res = await getRollCallsCourseDetailApi(address, date as string, begin_time)
        setDetailData(res.data)
        if (!res.data.courses.length || !res.data.courses[0].roll_calls.length) {
            setCanRollCall(false)
        } else {
            setCanRollCall(true)
        }
        closeLoading();
    }

    useEffect(() => {
        startLoading()
        // 加载泳池列表
        getSwimsListApi().then(res => {
            const courses = Object.keys(res.data).map(key => {
                return {
                    label: key,
                    value: res.data[key]
                }
            })
            setSwinsOptions(courses)
        }).finally(() => {
            closeLoading()
        })
        updateTimeList(address as string)
        updateDetail(address as string, beginTime as string)
    }, [])

    return (
        <div className={'schedule-rollcall-page bottom-block-page'}>
            <div className={'filter-container'}>
                <DropdownBox datasource={swinsOptions} placeholder={t('CommonComponent.placeholder') as string} defaultValue={swinAddress}
                             onChange={handleCourseTypeChange}></DropdownBox>
                <DropdownBox datasource={timeList} placeholder={t('CommonComponent.placeholder') as string} defaultValue={time} onChange={handleTimeChange}></DropdownBox>
            </div>
            <div className={'info-form'}>
                <div className="form-item">
                    <div className="label">{t('ScheduleRollCall.Detail.開始時間')}：</div>
                    <div className="info-wrapper">{detailData.begin_time}</div>
                </div>
                <div className="form-item">
                    <div className="label">{t('ScheduleRollCall.Detail.結束時間')}：</div>
                    <div className="info-wrapper">{detailData.end_times}</div>
                </div>
                <div className="form-item">
                    <div className="label">{t('ScheduleRollCall.Detail.星期')}：</div>
                    <div className="info-wrapper">{detailData.weekday}</div>
                </div>
                <div className="form-item">
                    <div className="label">{t('ScheduleRollCall.Detail.教練')}：</div>
                    <div className="info-wrapper">{detailData.coaches}</div>
                </div>
            </div>
            <div className={'border-block-line'}></div>
            <CollapseList detailData={detailData} onStatusChange={handleStatusChange}></CollapseList>
            <div style={{padding: '12px'}} className={'bottom-absolute-block'}>
                <Button className={'primary-button-bg'} disabled={!canRollCall} color={'primary'} block={true} onClick={handlePatchSubmit}>{t('ScheduleRollCall.確認點名')}</Button>
            </div>

        </div>
    )
}