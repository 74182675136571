import {request} from "../utils/request";
import {Axios, AxiosResponse} from "axios";

export interface ICourseDetailRes {
    title: string;
    info: string;
    content: Record<string, string>;
    fee: string;
}

export const getCourseDetailApi = (course_id: string): Promise<AxiosResponse<ICourseDetailRes>> => {
    return request.get(`/courses/id/${course_id}`)
}