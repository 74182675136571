import {request} from "../utils/request";
import {Axios, AxiosResponse} from "axios";


export interface ISalesListItem {
    title: string
    content: Record<string, string>;
    pay_amount: string;
    is_paid: boolean;
    payment_status: string;
}

export interface ISalesListRes {
    data: Array<ISalesListItem>;
    page_num: number;
    per_page_num: number;
    total_num: number;
    total_page_num: number;
    show_soft_deleted: boolean;

}

export const getSalesListApi = (page_num: number): Promise<AxiosResponse<ISalesListRes>> => {
    return request.post(`/sales/pagination`, {
        page_num
    })
}