import { IProduct } from "../../api/product";
import { InfiniteScroll, List, Tabs, Image } from "antd-mobile";
import { ProductRate } from "./Rate";
export default function LB01(props: { item: IProduct }) {
  const { item } = props;
  return (
    <div className="list-item list-item-all" key={item.product_id}>
      <Image className="image" src={item.pic_url}></Image>
      <div className="right-wrapper">
        <div className="title">{item.title}</div>
        <div className="sub-title">{item.subtitle}</div>
        <div className="shop">
          <Image className="shop-image" src={item.shop_pic_url}></Image>
          <span className="shop-name">{item.shop}</span>
          <ProductRate rate={item.rating}></ProductRate>
        </div>
        <div className="money">
          <span className="currency">{item.currency}</span>
          {item.unit_amount}
        </div>
      </div>
      {/* <div className="title">{item.name}</div>
      <div className="money">
        <span className="currency">{item.currency}</span>
        {item.unit_amount}
      </div> */}
    </div>
  );
}
