import {request} from "../utils/request";
import {AxiosResponse} from "axios";

export const loginApi = (params: {
    login_name: string,
    password: string
}): Promise<AxiosResponse<{
    x_token: string,
    token_type: string
}>> => {
    return request.post('/login', params)
}

export interface IPermission {
    id: string
    parent_id: string
    type: string
    front_type: string
    path: string
    hidden: string | boolean
    component: string
    redirect: string
    name: string
    title: string
    icon: string
    children?: IPermission[]
    sort?: number
}
export interface IPermissionMenuData {
    lang: string
    nickname: string
    role: string
    permissions: IPermission[]
}
export const permissionApi = (token?: string): Promise<AxiosResponse<IPermissionMenuData>> => {
    return request.get('/permissions/menu/web', {
        params: {
            x_token: token || ''
        }
    })
}