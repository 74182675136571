import React, {ReactNode} from 'react';
import {NavBar} from "antd-mobile";
import {useNavigate} from "react-router";

import './title-page.css';
import {useTranslation} from "react-i18next";

interface TitlePageProps {
    title: string,
    children: ReactNode,
    hideNav?: boolean
    hidden?: boolean
    right?: ReactNode
}

export default function TitlePage(props: TitlePageProps) {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const handleBack = () => {
        navigate(-1)
    }
    return <div className={'title-page'}>
        {
            !props.hidden ? <NavBar className={"navbar"} onBack={handleBack} backArrow={props.hideNav ? false : true} right={props.right}>
            {props.title}
        </NavBar> : null
        }
        

        <div className={'page-content'}>
            {
                props.children
            }

        </div>

    </div>;
}