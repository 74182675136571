import {request} from "../utils/request";
import {Axios, AxiosResponse} from "axios";

export interface IGroupingNumRes {
    [props: string]: {
        total: number
        ungrouped: number
        is_holiday: number
    }
}

// 獲取當月每日分組情況
export const getGroupingNumApi = (year_month: string, swimCode: string): Promise<AxiosResponse<IGroupingNumRes>> => {
    return request.get(`/groups/grouping_num/${swimCode}`, {
        params: {
            year_month
        }
    })
}

export interface IGroupingUnGroupedStudent {
    address_code: string
    info: string
}

export interface IGroupingInfoRes {
    ungrouped_students: Array<IGroupingUnGroupedStudent>,
    group_info_list: Array<{
        group_detail_id: string
        info: string
        coach?: string
    }>
    coaches?: string,
    date?: string,
    weekday?: string
}

export const getGroupingInfoByDateApi = (date: string, swimCode: string, time_range?: string): Promise<AxiosResponse<IGroupingInfoRes>> => {
    return request.get(`/groups/${swimCode}`, {
        params: {
            date,
            time_range
            // lang: 'cn'
        }
    })
}

export const getGroupingTimeListApi = (date: string, swimCode: string): Promise<AxiosResponse<string[]>> => {
    return request.get(`/groups/time_range/list/${swimCode}`, {
        params: {
            date
        }
    })
}

export const getCoachListApi = (swimCode: string, date: string, time_range: string, mode: string, course_id?: string) => {
    let sendData = {
        date,
        time_range,
        mode,
        course_id
    }
    if (!course_id) {
        delete sendData.course_id
    }
    if (!date) {
        // @ts-ignore
        delete sendData['date']
        // @ts-ignore
        delete sendData['time_range']
    }
    return request.get(`/coaches/list/${swimCode}`, {
        params: sendData
    })
}

export interface IGroupDetailRes {
    coach: string
    date: string
    weekday: string
    swimming_pool: string
    student_num: number
    ungrouped_student_num: number
    grouped_students: Array<{
        roll_call_id: string
        student_info: string
    }>
    ungrouped_students: Array<{
        roll_call_id: string
        student_info: string
    }>
}

export const getGroupDetailApi = (group_detail_id: string): Promise<AxiosResponse<IGroupDetailRes>> => {
    return request.get(`/groups/group_detail/${group_detail_id}`)
}

export const patchGroupStudentsApi = (mode: string, params: {
    roll_call_ids: Array<string>
    group_detail_id: string
}) => {
    return request.patch(`/groups/students`, params, {
        params: {
            mode
        }
    })
}

export const getCourseListApi = (mode: string, address: string, date: string, time_range: string): Promise<AxiosResponse<Record<string, string>>> => {
    return request.get(`/courses/list/${address}`, {
        params: {
            mode,
            date,
            time_range,
        }
    })
}


export const createGroupApi =(coach_id: string, mode: string, others: string | {
    address_code: string
    date: string
    time_range: string
} ) => {
    let params: Record<string, string> = {
        coach_id,
        mode
    }

    if (typeof others === 'string') {
        // course_id
        params['course_id'] = others
    } else {
        params = {
            ...params,
            ...others
        }
    }
    return request.post(`/groups`, params)
}