import React, {useState} from 'react';
import bgImg from '../../assets/me/bg.svg'
import courseImg from '../../assets/me/course.svg'
import leaveImg from '../../assets/me/leave.svg'
import estimateImg from '../../assets/me/estimate.svg'
import passwordImg from '../../assets/me/password.svg'
import settingImg from '../../assets/me/setting.svg'
import scheduleImg from '../../assets/me/schedule.svg'
import approvalImg from '../../assets/me/approval.svg'
import groupImg from '../../assets/me/group.svg'
import salesImg from '../../assets/me/sales.svg'

import './me.css'
import {useApp} from "../../context/app-context";
import {checkPermission} from "../../utils/user";
import {IPermission, IPermissionMenuData} from "../../api/auth";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import {useAliveController} from "react-activation";

interface IMenuItem {
    key: string
    name: string
    img: string
    path?: string
    sort?: number,
    t: string
}

export default function Me() {

    const {user} = useApp()

    const navigate = useNavigate()

    const { t } = useTranslation()

    const { drop } =  useAliveController()
    // 刪除keealive
    drop('schedule')

    const isAdmin = user?.role === 'Admin'
    let [list, setList] = useState<Array<IMenuItem>>([{
        key: 'Course',
        name: '我的課程',
        img: courseImg,
        t: 'Me.menu.我的課程'
    }, {
        name: '課程時間表',
        key: 'Schedule',
        img: scheduleImg,
        path: '/schedule',
        t: 'Me.menu.課程時間表'
    }, {
        name: '銷售單',
        key: 'Sale',
        img: salesImg,
        path: '/sales-order',
        t: 'Me.menu.銷售單'
    }, {
        name: '請假',
        key: 'LeaveApproval',
        img: leaveImg,
        t: 'Me.menu.請假'
    }, {
        name: '請假審批',
        key: 'LeaveApproval',
        img: approvalImg,
        t: 'Me.menu.請假審批'
    }, {
        name: '課程評估',
        key: 'Assessment',
        img: estimateImg,
        t: 'Me.menu.課程評估'
    }, {
        name: '課程分組',
        key: 'Grouping',
        img: groupImg,
        path: '/grouping',
        t: 'Me.menu.課程分組'
    }, {
        name: '消息推送',
        key: 'NotificationPush',
        img: groupImg,
        t: 'Me.menu.消息推送'
    }, {
        name: '修改密碼',
        key: 'ChangePassword',
        img: passwordImg,
        t: 'Me.menu.修改密碼'
    }, {
        name: '設置',
        key: 'Setting',
        path: '/setting',
        img: settingImg,
        t: 'Me.menu.設置'
    }])

    const permissions = useApp().user?.permissions || []

    let map: Record<string, IPermission> = {}
    permissions.forEach(permission => {
        map[permission.name] = permission
    })
    list.forEach(item => {

        const sort: number = map[item.key]?.sort || -1
        if (sort !== undefined) {
            item.sort = sort
        }
    })
    const sortList = list.sort((a, b) => {
        return (a.sort || -1) - (b.sort || -1)
    })
    list = sortList
    // setList(sortList)

    const handleItemClick = (item: IMenuItem) => {
        if (item.path) {
            navigate(item.path)
        }

    }
    console.log('me---------')


    return <div className="me-page">
        <div className={"me-content"}>
            <img className={"bgImg"} src={bgImg} alt=""/>
            <div className={"title-content"}>
                <span className={"title"}>{t('Me.title')}</span>
                <div className={"bell-content"}>
                </div>
            </div>
            <div className={"detail-content"}>
                <div className={"left"}>
                    <img src={bgImg} alt=""/>
                </div>
                <div className={"right"}>
                    <div className={"name"}>{user?.nickname}</div>
                    <div className={"role"}>{user?.role}</div>
                </div>
            </div>
        </div>
        <div className={"me-list"}>
            {
                list.filter(item => {
                  return checkPermission(item.key)
                }).map((item, index) => {
                    return <div className={"me-list-item"} key={index} onClick={() => handleItemClick(item)}>
                        <img src={item.img} alt=""/>{t(item.t)}
                    </div>
                })
            }
        </div>
    </div>
}