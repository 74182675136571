import {IPermissionMenuData} from "../api/auth";

export interface IUser extends IPermissionMenuData {}

export const setUser = (user: IUser | undefined) => {
    if (!user) {
        window.localStorage.removeItem('user')
        return
    }
    window.localStorage.setItem('user', JSON.stringify(user))
}
export const getUser = () => {
    const user = window.localStorage.getItem('user') || '{}'
    if (user === '{}') return undefined
    return JSON.parse(user)
}

export const checkPermission = (name: string) => {
    const user: IUser | undefined = getUser()
    if (!user) return false
    const permissions = user.permissions
    if (!permissions.length) return false
    // 当前只做一层循环
    for (let i = 0; i < permissions.length; i++) {
        const permission = permissions[i]
        // @ts-ignore
        if (permission.name === name && (permission.hidden !== true && permission.hidden !== 'true')) {
            return true
        }
    }
    return false
}

export const getLang = () => {
    const user: IUser | undefined = getUser()
    if (!user) return null
    return user.lang || null
}

export const updateLang = (lang: string) => {
    const user: IUser | undefined = getUser()
    if (!user) return
    user.lang = lang.toLowerCase();
    setUser(user)
}