import React from 'react'
import { SpinLoading } from "antd-mobile";
import './Loading.css'
interface LoadingProps {
    loading: boolean
}
export default function Loading(props: LoadingProps) {

    return props.loading ? <div className={"loading-container"}>
        <div className={"loading-box"}>
            <SpinLoading />
        </div>
    </div> : null
}