import React, {useEffect} from 'react';
import {useNavigate} from "react-router";
import BottomTab from "./BottomTab";
import {Route, Routes} from "react-router";
import Main from "../main/main";
import Me from "../me/me";

import './home.css';
import {useApp} from "../../context/app-context";
import Product from '../product/Product';

export default function Home() {
    let nativate = useNavigate();

    // useEffect(() => {
    //     nativate("/login")
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])
    const { user } = useApp()
    if (!user) {
        setTimeout(() => {
            nativate('/login')
        })
    }


    return <div className="home-page">
        <div className={"home-content"}>
            <Routes>
                <Route path={"/me"} element={<Me/>}></Route>
                <Route path={"product"} element={<Product/>}></Route>
                <Route path={"/"} element={<Main/>}></Route>
            </Routes>
        </div>
        <BottomTab/>
    </div>
}