import {Grid, Space, SearchBar, List, InfiniteScroll} from 'antd-mobile'
import {useTranslation} from "react-i18next";
import './student-contact.scss';
import {useEffect, useState} from "react";
import {showToast} from "../../utils/toast";
import {getStudentListApi, IStudentListItem, IStudentListRes} from "../../api/student";

export interface IStudentContactProps {
    onItemClick?: (item: IStudentListItem) => void
}
export default function StudentContact(props: IStudentContactProps) {
    const {t} = useTranslation()
    const [search, setSearch] = useState({
        keyword: '',
        phone: ''
    })
    const [list, setList] = useState<Array<IStudentListItem>>([])
    const [pageNum, setPageNum] = useState(0)
    const [hasMore, setHasMore] = useState(false)

    useEffect(() => {
        reload();
    }, [])

    const loadMore = async (pageNum: number, reload?: boolean) => {
        try {
            const data = await getStudentListApi(pageNum, 20, search)
            

            setList(reload ?data.data.data : list.concat(data.data.data || []))
            setPageNum(data.data.page_num)
            setHasMore(data.data.total_page_num > pageNum)
        } catch (e) {
            showToast('load data error', 'fail')
            if(pageNum === 1) {
                setHasMore(false)
            }
        }
    }
    const reload = () => {
        setList([])
        setPageNum(0)
        setHasMore(false)
        loadMore(pageNum, true)
    }
    const handleNameSearch = (val: string) => {
        setSearch({
            keyword: val,
            phone: search.phone
        })
        // reload()
    }

    const handlePhoneSearch = (val: string) => {
        setSearch({
            keyword: search.keyword,
            phone: val
        })
        // reload()
    }
    useEffect(() => {
        reload();
    }, [search])

    const handleClick = (item: IStudentListItem) => {
        props.onItemClick && props.onItemClick(item)
    }
    return <div className={'student-contact-page'}>
        <Grid className={'search-wrapper'} columns={2} gap={8}>
            <Grid.Item>
                <SearchBar placeholder={t('StudentContact.namePlaceholder') || ''}
                           onSearch={(val: string) => handleNameSearch(val)}/>
            </Grid.Item>
            <Grid.Item>
                <SearchBar placeholder={t('StudentContact.phonePlaceholder') || ''}
                           onSearch={(val: string) => handlePhoneSearch(val)}/>
            </Grid.Item>
        </Grid>
        <div className={'list-wrapper'}>
            <List className={'list'}>
                {
                    list.map(item => {
                        return <List.Item key={item.student_id} className={'list-item'} onClick={() => handleClick(item)}>
                            <span className={'left'}>{item.info}</span>
                            <span className={'right'}>{item.phone}</span>
                        </List.Item>
                    })}
            </List>
            <InfiniteScroll loadMore={() => loadMore(pageNum + 1)} hasMore={hasMore}/>
        </div>
    </div>
}