import React, {FC, useEffect, useState} from 'react'
import DropdownBox, {IDropdownOption} from "../../components/dropdown/Dropdown";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useApp} from "../../context/app-context";
import {getSwimsListApi} from "../../api/schedule";
import {getGroupingInfoByDateApi, getGroupingTimeListApi, IGroupingInfoRes} from "../../api/grouping";
import './grouping-select.css'
import {Button, List, Radio, Space} from "antd-mobile";
import dayjs from "dayjs";
import {showConfirm} from "../../hooks/useConfirm";

const GroupingSelect: FC = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {address, date} = useParams();
    const [swinsOptions, setSwinsOptions] = useState<Array<IDropdownOption>>([])
    const [swimAddress, setSwimAddress] = useState(address as string);
    const [timeList, setTimeList] = useState<Array<IDropdownOption>>([])
    const [time, setTime] = useState('')

    const [groupingDetail, setGroupingDetail] = useState<IGroupingInfoRes>({
        ungrouped_students: [],
        group_info_list: [],
        coaches: '',
        date: '',
        weekday: ''
    })


    const {startLoading, closeLoading} = useApp();


    const handleSwimAddressChange = (newVal: string | number) => {
        // 设置新的游泳地址
        setSwimAddress(newVal as string)
        // 需要更新选择时间段列表
        // updateTimeList(newVal as string)
    }

    const handleTimeChange = (newVal: string | number) => {
        // 更新时间
        setTime(newVal as string)
        // 重新查看详情
        // updateDetail(swimAddress, newVal as string)
    }

    const updateTimeList = async (address: string) => {
        const res = await getGroupingTimeListApi(date as string, address as string)
        const times = res.data.map(item => {
            return {
                label: item,
                value: item
            }
        })
        setTimeList(times)
        if (times.length) {
            setTime(times[0].value)
        }
    }

    // 點擊分組
    const handleGoGroupingSelect = (group_detail_id: string, coaches: string) => {
        const swimName = swinsOptions.find(op => op.value === swimAddress)?.label
        navigate(`/grouping/${group_detail_id}/${swimAddress}/${coaches}/${date}/${time}`)
    }

    // 新建分組
    const handleAddGroup = async () => {
        let mode = 'UNIQUE_DATE'
        const handleModeType = (newType: any) => {
            mode = newType
        }
        const { status, setClose } = await showConfirm(<div className={'grouping-page-submit-confirm'}>
            <Radio.Group defaultValue={mode} onChange={handleModeType}>
                <Space direction={'vertical'}>
                    <Radio value={'UNIQUE_DATE'}>{t('Grouping.Detail.整個課程')}</Radio>
                    <Radio value={'AVAILABLE'}>{t('Grouping.Detail.當天')}</Radio>
                </Space>
            </Radio.Group>
        </div>)

        if (status === true) {
            navigate(`/addgroup/${mode}/${address}/${date}/${time}`)
        } else {
        }
        setClose()
    }

    // 月份 跟泳池改變後，需要重新請求數據
    useEffect(() => {
        if (swimAddress && time) {
            startLoading()
            getGroupingInfoByDateApi(date as string, swimAddress, time).then(res => {
                setGroupingDetail(res.data)
                closeLoading();
            })
        }
    }, [swimAddress, time])

    useEffect(() => {
        startLoading()
        // 加載泳池列表
        getSwimsListApi({
            student_filter: true,
            date
        }).then(res => {
            const courses = Object.keys(res.data).map(key => {
                return {
                    label: key,
                    value: res.data[key]
                }
            })
            setSwinsOptions(courses)
        }).finally(() => {
            closeLoading()
        })
        updateTimeList(address as string)
    }, [])


    return <div className={'groupingSelect-page grouping-page  schedule-rollcall-page bottom-block-page'}>
        <div className={'filter-container'}>
            <DropdownBox datasource={swinsOptions} placeholder={t('CommonComponent.placeholder') as string}
                         defaultValue={swimAddress}
                         onChange={handleSwimAddressChange}></DropdownBox>
            <DropdownBox datasource={timeList} placeholder={t('CommonComponent.placeholder') as string}
                         defaultValue={time} onChange={handleTimeChange}></DropdownBox>
        </div>
        <div className={'info-form'}>
            <div className="form-item">
                <div className="label">{t('Grouping.Detail.日期')}：</div>
                <div className="info-wrapper">{groupingDetail.date || ''}</div>
            </div>
            <div className="form-item">
                <div className="label">{t('Grouping.Detail.星期')}：</div>
                <div className="info-wrapper">{groupingDetail.weekday}</div>
            </div>
            <div className="form-item">
                <div className="label">{t('Grouping.Detail.教練')}：</div>
                <div className="info-wrapper">{groupingDetail.coaches}</div>
            </div>
        </div>
        <div className={'border-block-line'}></div>
        <List className={'list-with-header'} header={t('Grouping.未分組人數')}>
            {
                groupingDetail.ungrouped_students.map((item, index) => {
                    return <List.Item key={index}>
                        {item.info}
                    </List.Item>
                })
            }
        </List>
        <div className={'border-block-line'}></div>
        <List className={'list-with-header'} header={t('Grouping.已建分組')}>
            {
                groupingDetail.group_info_list.map((item, index) => {
                    return <List.Item key={item.group_detail_id}>
                        <div className={'content'}>{item.info}</div>
                        <Button size={'mini'} color={"primary"} className={'right-wrapper primary-button-bg '}
                                onClick={() => handleGoGroupingSelect(item.group_detail_id, item.coach as string)}>{t('Grouping.分組')}</Button>
                    </List.Item>
                })
            }
        </List>
        <div className={'bottom-absolute-block'} style={{padding: '12px'}}>
            <Button className={'primary-button-bg'} color={'primary'}
                    block={true} onClick={handleAddGroup}>{t('Grouping.Detail.新建分組')}</Button>
        </div>
    </div>
}

export default GroupingSelect