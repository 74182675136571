import {request} from "../utils/request";
import {AxiosResponse} from "axios";

export const updateLangApi = (lang: string): Promise<AxiosResponse<{
    lang: string
}>> => {
    return request.patch('/users/me/lang', undefined, {
        params: {
            lang
        }
    })
}

export const getLangLocalesApi = (lang: string): Promise<AxiosResponse<Record<string, any>>> => {
    const locationPath = `${window.location.origin}${window.location.pathname}`
    return request.get(`${locationPath}/languages/${lang}.json`, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}