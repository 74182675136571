import {Dropdown, Space, Radio, Button, DropdownRef} from "antd-mobile";
import {useEffect, useRef, useState} from "react";
import './dropdown.css';
import {useTranslation} from "react-i18next";
export interface IDropdownProps {
    defaultValue?: string
    datasource: IDropdownOption[]
    placeholder?: string
    onChange?: (newVal: string | number) => void
}

export interface IDropdownOption {
    label: string
    value: string | number
}

export default function DropdownBox(props: IDropdownProps) {
    const [value, setValue] = useState<string | number | null>(props.defaultValue || null);
    const [label, setLabel] = useState(props.placeholder || '')
    const { t } = useTranslation()

    const ref = useRef<DropdownRef>(null)


    useEffect(() => {
        if (props.defaultValue) {
            const selectedItem = props.datasource.find(item => item.value === props.defaultValue)
            if (selectedItem) {
                setLabel(selectedItem.label)
            } else {
                setLabel('')
            }
        }
        setValue(props.defaultValue || null)
    }, [props.defaultValue, props.datasource])

    const handleChange = (value: string | number) => {
        setValue(value)
    }
    return <Dropdown ref={ref} className={'common-dropdown-container'} closeOnClickAway={false}>
        {
            <Dropdown.Item key={'value'} title={label || props.placeholder}>
                <div style={{padding: 12}}>
                    <Radio.Group defaultValue={value} onChange={handleChange}>
                        <Space direction={'vertical'} block>
                            {
                                props.datasource.map(item => {
                                    return <Radio block key={item.value} value={item.value}>{item.label}</Radio>
                                })
                            }

                        </Space>
                    </Radio.Group>
                    <Button color={'primary'} block onClick={() => {
                        if (props.defaultValue !== value) {
                            props.onChange && props.onChange(value || '')
                        }
                        ref.current?.close()
                    }
                    }>{t('CommonComponent.confirm')}</Button>
                </div>
            </Dropdown.Item>
        }
    </Dropdown>
}