import {Button, Calendar, CalendarRef, List, Space} from 'antd-mobile'
import './schedule.css'
import DropdownBox, {IDropdownOption} from "../../components/dropdown/Dropdown";
import {ReactNode, useEffect, useRef, useState} from "react";
import {
    getCourseLessonListApi,
    getCourseLessonNumApi,
    getSwimsListApi,
    ICourseLesson,
    ICourseLessonNumRes
} from "../../api/schedule";
import dayjs from "dayjs";
import {useApp} from "../../context/app-context";
import {useTranslation} from "react-i18next";
import {useNavigate, useLocation} from "react-router-dom";


export default function Schedule() {

    const {startLoading, closeLoading} = useApp();

    const { t } = useTranslation()

    const navigate = useNavigate()
    const { pathname } = useLocation()
    const isSignupSelectPage = pathname.includes('signup-select')

    let defaultDate = new Date();

    const [swinsOptions, setSwinsOptions] = useState<Array<IDropdownOption>>([])
    const [swimCode, setSwimCode] = useState('');
    const [courseLessonNum, setCourseLessonNum] = useState<ICourseLessonNumRes>({})

    const [courses, setCourses] = useState<Array<ICourseLesson>>([])

    const [selectedDate, setSelectedDate] = useState<Date>(defaultDate)
    const [selectedMonth, setSelectedMonth] = useState<string>(dayjs(selectedDate).format('YYYY-MM'))


    const caledarRef = useRef<null | CalendarRef>(null)


    let lastDate = useRef(defaultDate)
    const handleCaledarChange = (date: Date | null) => {
        if (date == null && lastDate.current) {
            setSelectedDate(lastDate.current);
            return
        }
        setSelectedDate(date as Date)
        if (date) {
            lastDate.current = date
        }
    }

    const handleCaledarPageChange = (year: number, month: number) => {
        const d = dayjs(selectedDate).set('year', year).set('month', month - 1).format('YYYY-MM-DD')
        setSelectedDate(new Date(d))
        setSelectedMonth((dayjs(d).format('YYYY-MM')))
        // 由於不知道哪一天，只能需要查詢日曆課程數量就行
        // getCourseLessonNumApi(dayjs(selectedDate).format(('YYYY-MM-DD')), swimCode).then(res => {
        //     setCourseLessonNum(res.data)
        // })
        // 切換日曆，需要清除掉課程列表
    }

    const handleSwimsTypeChange = (newVal: string | number) => {
        setSwimCode(newVal as string)
    }

    // 點擊今日，跳轉到今天的數據
    const handleToday = () => {
        setSelectedDate(new Date())
        caledarRef.current?.jumpToToday()
    }

    const handleRollCall = (course: ICourseLesson) => {
        if (isSignupSelectPage) {
            // TODO 暫不實現
            navigate(`/signup-submit/${course.course_id}`)
            return
        } else {
            navigate(`/schedule-rollcall/${course.address_code}/${dayjs(selectedDate).format('YYYY-MM-DD')}/${course.begin_time}`)
        }
    }

    // mount 加载泳池列表
    useEffect(() => {
        startLoading()
        // 加载泳池列表
        getSwimsListApi().then(res => {
            const swims = Object.keys(res.data).map(key => {
                return {
                    label: key,
                    value: res.data[key]
                }
            })
            setSwinsOptions(swims)
            setSwimCode(swims[0].value)
        }).finally(() => {
            closeLoading()
        })
    }, [])

    // 月份改变后，需要重新请求课程总数
    useEffect(() => {
        if (swimCode && selectedMonth) {
            startLoading()
            getCourseLessonNumApi(dayjs(selectedDate).format(('YYYY-MM')), swimCode).then(res => {
                setCourseLessonNum(res.data)
                closeLoading()
            })
        }
    }, [swimCode, selectedMonth])


    // 泳池选择后，请求课程总数以及课程列表
    useEffect(() => {
        // if (!selectedDate && lastDate.current) {
        //     startLoading()
        //     getCourseLessonListApi(dayjs(lastDate.current).format(('YYYY-MM-DD')), swimCode).then(res => {
        //         setCourses(res.data)
        //         closeLoading()
        //     })
        //     return
        // }
        if (swimCode && selectedDate) {
            startLoading()
            getCourseLessonListApi(dayjs(selectedDate).format(('YYYY-MM-DD')), swimCode).then(res => {
                setCourses(res.data)
                closeLoading()
            })
        }
    }, [swimCode, selectedDate])


    return <div className={'schedule-page'}>
        <div className={'calendar-container'} data-value={selectedDate}>
            <DropdownBox datasource={swinsOptions} placeholder={t('CommonComponent.placeholder') as string} defaultValue={swimCode}
                         onChange={handleSwimsTypeChange}></DropdownBox>
            <Calendar
                ref={caledarRef}
                selectionMode='single'
                defaultValue={selectedDate}
                value={selectedDate}
                onChange={handleCaledarChange}
                onPageChange={handleCaledarPageChange}
                renderLabel={date => {
                    const dateStr = dayjs(date).format('YYYY-MM-DD')
                    const lessonObj = courseLessonNum[dateStr];
                    if (lessonObj) {
                        if (lessonObj.lesson_num) {
                            let className = 'lesson_num'
                            if (lessonObj.is_holiday) {
                                // className += ' red'
                            }
                            return <div className={className}>{lessonObj.lesson_num}</div>
                        }
                    }
                    return null
                }}
                renderDate={date => {
                    const d = dayjs(date)
                    const dateStr = d.format('YYYY-MM-DD')
                    const lessonObj = courseLessonNum[dateStr];
                    let isHoliday = false
                    if (lessonObj && lessonObj.is_holiday) {
                        isHoliday = true
                    }
                    let clasName = 'day_num'
                    if (isHoliday) {
                        clasName += ' red'
                    }
                    return <div className={clasName}>{d.date()}</div>
                }}
            />
        </div>
        <div className={'tag-container'}>
            <div className={'item primary'} onClick={handleToday}>
                <span className={'circle primary'}></span>
                <span>{t('Schedule.today')}</span>
            </div>
            <div className={'item'}>
                <span className={'circle warning'}></span>
                <span>{t('Schedule.course')}</span>
            </div>
        </div>
        <List header={<ListItem time={t('Schedule.table.時間')} course={t('Schedule.table.課程')} attendance={isSignupSelectPage ? t('SignupSelect.table.已報名人數') :  t('Schedule.table.點名人數')} operation={''}/>}>
            {
                courses.map((course, index) => <List.Item key={index}>
                    <ListItem time={course.time} course={course.course} attendance={ isSignupSelectPage ? course.sign_up + '' :  course.attendance} operation={<Button onClick={() => handleRollCall(course)} color='primary' fill='none'>
                        {isSignupSelectPage ? t('SignupSelect.table.報名') : t('Schedule.table.點名')}
                    </Button>}></ListItem>
                </List.Item>)
            }
        </List>

    </div>
}

export interface IListItem {
    time: string
    course: string
    attendance: string
    operation: string | ReactNode
}

function ListItem(props: IListItem) {
    const { t } = useTranslation()
    return <div className={'list-item'}>
        <div>{props.time}</div>
        <div>{props.course}</div>
        <div>{props.attendance}</div>
        <div>
            {
                props.operation
            }
            </div>
    </div>
}