import {request} from "../utils/request";
import {Axios, AxiosResponse} from "axios";
import {getUser} from "../utils/user";

export const getPaymentMethodListApi = (): Promise<AxiosResponse<{
    ["現金"]: string;
    ["轉帳"]: string;
    ["未支付"]: string;
    [x: string]: string;
}>> => {
    const lang = (getUser()?.lang || 'cn').toLowerCase()
    return request.get('/payment/method/list', {
        params: {
            lang,
        }
    })
}

export const postPaymentApi = (paymentData: {
    course_id: string;
    student_id: string;
    new_student: {
        name: string;
        birthday: string;
        sex: string;
        phone: string;
    }
    discount: number;
    payment_method: string;
}, mode?: string) => {
    let data = {
        ...paymentData,
        mode
    }
    if (!data.mode) {
        // @ts-ignore
        delete data.mode
    }
    return request.post(`/sales`, data)
}