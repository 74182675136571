import { request } from "../utils/request";
import { Axios, AxiosResponse } from "axios";

export interface ICommonColumn {
  column_name: string;
  column_value: string;
}

export const getProductTypeApi = (): Promise<
  AxiosResponse<{
    columns: Array<ICommonColumn>;
  }>
> => {
  return request.get(`/products/columns/web`);
};

export interface IProduct {
  display_style: string;
  product_id: string;
  name: string;
  pic_url: string;
  currency: string;
  unit_amount: string;
  title: string;
  subtitle: string;
  shop_pic_url: string;
  rating: number;
  shop: string;
}

export const getProductListApi = (
  column_value: string,
  page_num: number,
  per_page_num = 10,
  show_soft_deleted = false
): Promise<
  AxiosResponse<{
    data: Array<IProduct>;
    "page_num": number,
    "per_page_num": number,
    "total_num": number,
    "total_page_num": number,
    "show_soft_deleted": number,
    "order_by": null,
    "filter_by": null,
    "search_by": null
  }>
> => {
  const data = {
    front_type: "web",
    column_value,
    pagination: {
      page_num,
      per_page_num,
      show_soft_deleted,
    },
  };
  return request.post("/products/pagination", data);
};
