import {request} from "../utils/request";
import {AxiosResponse} from "axios";

export const getSwimsListApi = (params?: {
    show_all?: boolean,
    student_filter?: boolean,
    date?: string
}): Promise<AxiosResponse<Record<string, string>>> => {
    let sendParams = {
        show_all: params?.show_all || true,
        student_filter: params?.student_filter || false,
        date: params?.date || ''
    }
    if (!sendParams.date) {
        // @ts-ignore
        delete sendParams['date']
    }

    return request.get('/swimming_pools/list', {
        params: sendParams
    })
}

export interface ICourseLessonNumRes {
    [props: string]: {
        lesson_num: number
        is_holiday: boolean
    }
}

export const getCourseLessonNumApi = (year_month: string, courseType: string): Promise<AxiosResponse<ICourseLessonNumRes>> => {
    return request.get(`/courses/lesson_num/${courseType}`, {
        params: {
            year_month
        }
    })
}

export interface ICourseLesson {
    time: string;
    course: string;
    course_id: string;
    attendance: string
    address_code: string
    begin_time: string
    sign_up: number
}

export const getCourseLessonListApi = (date: string, courseType: string): Promise<AxiosResponse<Array<ICourseLesson>>> => {
    return request(`/courses/${courseType}`, {
        params: {
            date
        }
    })
}

export const getRollCallsTimeListApi = (date: string, swimAddressCode: string): Promise<AxiosResponse<Array<string>>> => {
    return request(`/roll_calls/time_list/${swimAddressCode}?date=${date}`)
}


export interface  IRollCallItem {
    roll_call_id: string
    student_info: string
    status_code: string

    whatsapp?: string
}
export interface IRollCallCourseItem {
    course_info: string
    roll_calls: Array<IRollCallItem>
}
export interface IRollCallsCourseDetail {
    begin_time: string
    end_times: string
    weekday: string
    coaches: string
    roll_call_summary: string
    roll_call_status_dict: Record<string, string>
    courses: Array<IRollCallCourseItem>
}

export const getRollCallsCourseDetailApi = (swimAddressCode: string, date: string, begin_time: string): Promise<AxiosResponse<IRollCallsCourseDetail>> => {
    return request(`/roll_calls/course_details/${swimAddressCode}`, {
        params: {
            date,
            begin_time
        }
    })
}

export const patchRollCallsApi = (data: Array<{
    roll_call_id: string
    status_code: string
}>): Promise<AxiosResponse<string>> => {
    return request('/roll_calls/batch', {
        data,
        method: 'patch'
    })
}