import {Dialog} from "antd-mobile";
import {useTranslation} from "react-i18next";
import {ReactNode, useRef, useState} from "react";
import {DialogShowHandler} from "antd-mobile/es/components/dialog";
import {t} from "i18next";

export const showConfirm = (content: string | ReactNode, title?: string): Promise<{
    status: boolean,
    setClose: () => void
}>=> {
    title = (title || t('CommonComponent.Confirm.title') as string)
    // let [res, setRes] = useState<boolean | undefined>(undefined)
    return new Promise((resolve) => {
        const handler = Dialog.show({
            title: title,
            className: 'common-confirm',
            content,
            // destroyOnClose: true,
            // cancelText: t('CommonComponent.cancel') as string,
            // confirmText: t('CommonComponent.confirm') as string,
            actions: [{
                key: 'close',
                text: t('CommonComponent.cancel') as string,
                onClick: () => {
                    // cancel && cancel(() => {
                    //     handler.current?.close()
                    //     reject()
                    // })
                    // setRes(false)
                    resolve({
                        status: false,
                        setClose: () => {
                            handler.close()
                        }
                    })
                }
            }, {
                key: 'confirm',
                text: t('CommonComponent.confirm') as string,
                onClick: () => {
                    // confirm && confirm(() => {
                    //     handler.current?.close();
                    // })
                    // setRes(true)
                    resolve({
                        status: true,
                        setClose: () => {
                            handler.close()
                        }
                    })

                }
            }]
        })
    })
}