import {FC, useEffect, useRef, useState} from "react";
import {useApp} from "../../context/app-context";
import {useTranslation} from "react-i18next";
import DropdownBox, {IDropdownOption} from "../../components/dropdown/Dropdown";
import {Button, Calendar, CalendarRef, List} from "antd-mobile";
import dayjs from "dayjs";
import {getSwimsListApi} from "../../api/schedule";
import {getGroupingInfoByDateApi, getGroupingNumApi, IGroupingInfoRes, IGroupingNumRes} from "../../api/grouping";
import './grouping.css'
import {useNavigate} from "react-router-dom";

const Grouping: FC = () => {
    const {startLoading, closeLoading} = useApp();
    const {t} = useTranslation()
    const navigate = useNavigate()
    let defaultDate = new Date();
    // if (process.env.NODE_ENV === 'development') {
    //     defaultDate = new Date('2023-03-05')
    // }
    const [swinsOptions, setSwinsOptions] = useState<Array<IDropdownOption>>([])
    const [swimCode, setSwimCode] = useState('');

    const [groupingNum, setGroupingNum] = useState<IGroupingNumRes>({})

    const [groupingDetail, setGroupingDetail] = useState<IGroupingInfoRes>({
        ungrouped_students: [],
        group_info_list: []
    })


    const [selectedDate, setSelectedDate] = useState<Date>(defaultDate)
    const [selectedMonth, setSelectedMonth] = useState<string>(dayjs(selectedDate).format('YYYY-MM'))


    const caledarRef = useRef<null | CalendarRef>(null)

    const handleCaledarChange = (date: Date | null) => {
        setSelectedDate(date as Date)
    }
    const handleCaledarPageChange = (year: number, month: number) => {
        const d = dayjs(selectedDate).set('year', year).set('month', month - 1).format('YYYY-MM-DD')
        setSelectedDate(new Date(d))
        setSelectedMonth((dayjs(d).format('YYYY-MM')))
        // 由於不知道哪一天，只能需要查詢日曆課程數量就行
        // getCourseLessonNumApi(dayjs(selectedDate).format(('YYYY-MM-DD')), courseType).then(res => {
        //     setCourseLessonNum(res.data)
        // })
        // 切換日曆，需要清除掉課程列表
    }
    const handleSwimsTypeChange = (newVal: string | number) => {
        setSwimCode(newVal as string)
    }

    // 點擊今日，跳轉到今日的數據
    const handleToday = () => {
        setSelectedDate(new Date())
        caledarRef.current?.jumpToToday()
    }
    // 點擊分組
    const handleGoGroupingSelect = (swimCode: string) => {
        navigate(`/grouping/${swimCode}/${dayjs(selectedDate).format('YYYY-MM-DD')}`)
    }

    // mount加載泳池列表
    useEffect(() => {
        startLoading()
        getSwimsListApi().then(res => {
            const swims = Object.keys(res.data).map(key => {
                return {
                    label: key,
                    value: res.data[key]
                }
            })
            setSwinsOptions(swims)
            setSwimCode(swims[0].value)
        }).finally(() => {
            closeLoading()
        })
    }, [])

    // 月份，跟泳池改變後，需要重新請求數據
    useEffect(() => {
        if (swimCode && selectedMonth) {
            startLoading()
            getGroupingNumApi(dayjs(selectedDate).format(('YYYY-MM')), swimCode).then(res => {
                setGroupingNum(res.data)
                closeLoading()
            })
        }
    }, [swimCode, selectedMonth])

    // 泳池選擇後，請求分組總數以及以分組未分組詳細數據列表
    useEffect(() => {
        if (swimCode && selectedDate) {
            startLoading()
            getGroupingInfoByDateApi(dayjs(selectedDate).format(('YYYY-MM-DD')), swimCode).then(res => {
                setGroupingDetail(res.data)
                closeLoading();
            })
        }
    }, [swimCode, selectedDate])


    return <div className="grouping-page schedule-page">
        <div className={'calendar-container'} data-value={selectedDate}>
            <DropdownBox datasource={swinsOptions} placeholder={t('CommonComponent.placeholder') as string}
                         defaultValue={swimCode}
                         onChange={handleSwimsTypeChange}></DropdownBox>
            <Calendar
                ref={caledarRef}
                selectionMode='single'
                defaultValue={selectedDate}
                value={selectedDate}
                onChange={handleCaledarChange}
                onPageChange={handleCaledarPageChange}
                renderLabel={date => {
                    const dateStr = dayjs(date).format('YYYY-MM-DD')
                    const grouping = groupingNum[dateStr];
                    // if (lessonObj) {
                    //     if (lessonObj.lesson_num) {
                    //         let className = 'lesson_num'
                    //         if (lessonObj.is_holiday) {
                    //             // className += ' red'
                    //         }
                    //         return <div className={className}>{lessonObj.lesson_num}</div>
                    //     }
                    // }
                    return <div className={'group-info'}>
                        {
                            grouping?.ungrouped ? <span className={'ungrouped'}>{grouping?.ungrouped}</span> : null
                        }

                        {
                            grouping?.total ? <span className={'total'}>{grouping?.total}</span> : null
                        }
                        {/*<span className={'ungrouped'}>0</span>*/}
                        {/*<span className={'total'}>12</span>*/}
                    </div>
                }}
                renderDate={date => {
                    const d = dayjs(date)
                    const dateStr = d.format('YYYY-MM-DD')
                    const grouping = groupingNum[dateStr];
                    let isHoliday = false
                    if (grouping && grouping.is_holiday) {
                        isHoliday = true
                    }
                    let className = 'day_num'
                    if (isHoliday) {
                        className += ' red'
                    }
                    return <div className={className}>{d.date()}</div>
                }}
            />
        </div>
        <div className={'tag-container'}>
            <div className={'item primary'} onClick={handleToday}>
                <span className={'circle primary'}></span>
                <span>{t('Grouping.今日')}</span>
            </div>
            <div className={'item'}>
                <span className={'circle warning'}></span>
                <span>{t('Grouping.未分組人數')}</span>
            </div>
            <div className={'item'}>
                <span className={'circle success'}></span>
                <span>{t('Grouping.總人數')}</span>
            </div>
        </div>
        <List className={'list-with-header'} header={t('Grouping.未分組人數')}>
            {
                groupingDetail.ungrouped_students.map((item, index) => {
                    return <List.Item key={index}>
                        <div className={'content'}>{item.info}</div>
                        <Button size={'mini'} color={"primary"} className={'right-wrapper primary-button-bg '}
                                onClick={() => handleGoGroupingSelect(item.address_code)}>{t('Grouping.分組')}</Button>
                    </List.Item>
                })
            }
        </List>
        <div className={'border-block-line'}></div>
        <List className={'list-with-header'} header={t('Grouping.已建分組')}>
            {
                groupingDetail.group_info_list.map((item, index) => {
                    return <List.Item key={item.group_detail_id}>
                        {item.info}
                    </List.Item>
                })
            }
        </List>
    </div>
}
export default Grouping