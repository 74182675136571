import React, { useEffect, useRef, useState } from "react";
import { Swiper, Space, InfiniteScroll } from "antd-mobile";
import styles from "./main.module.scss";
import logoImg from "../../assets/main/logo.svg";
import { useTranslation } from "react-i18next";
import { RightOutline } from "antd-mobile-icons";
import { getCarouselListApi, ICarouselItem } from "../../api/carousel";
import { useNavigate } from "react-router";
import { getPromotionListApi } from "../../api/promotions";
import { showToast } from "../../utils/toast";
import { IProduct } from "../../api/product";
import LA01 from "../product/LA01";
import LB01 from "../product/LB01";

import "../product/product.scss";
import "../product/list.scss";

export default function Main() {
  // const banners = ['/images/banner/b1.png', '/images/banner/b2.png', '/images/banner/b3.png']
  const [banners, setBanners] = useState<ICarouselItem[]>([]);
  const nav = useNavigate();
  useEffect(() => {
    getCarouselListApi().then((res) => {
      setBanners(res.data);
    });
  }, []);

  const banners2 = [
    {
      img: "/images/banner/b1.png",
      text:
        "1在自由泳中,好多1在自由泳中1在自由泳中1在自由泳中1在自由泳中1在自由泳中1在自由泳中1在自由泳中人傾向於向前看,而不是向下看.這種方法的問題在於它會導致腿部和臀部下沉." +
        "因此,你必須更加努力地打腿來避免腿部下沉,這樣你會",
    },
    {
      img: "/images/banner/b2.png",
      text:
        "2在自由泳中,好多人傾向於向前看泳中1在自由泳中1在自由泳中1在自由泳中1在自,而不是向下看.這種方法的問題在於它會導致腿部和臀部下沉." +
        "因此,你必須更加努力地打腿來避免腿部下沉,這樣你會",
    },
    {
      img: "/images/banner/b3.png",
      text:
        "3在自由泳中,好多人傾向於向前泳中1在自由泳中1在自由泳中1在自由泳中1在自看,而不是向下看.這種方法的問題在於它會導致腿部和臀部下沉." +
        "因此,你必須更加努力地打腿來避免腿部下沉,這樣你會",
    },
  ];
  const { t } = useTranslation();

  const listRef = useRef<HTMLDivElement>(null);

  const [list, setList] = useState<Array<IProduct>>([]);

  const [pageNum, setPageNum] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    listRef.current?.scrollTo(0, 0);
    reload();
  }, []);

  const loadMore = async (pageNum: number, reload?: boolean) => {
    try {
      const data = await getPromotionListApi("", reload ? 0 : pageNum);
      const newList = reload
        ? data.data.data
        : list.concat(data.data.data || []);
      setList(newList);
      setPageNum(data.data.page_num);
      setHasMore(data.data.total_page_num > pageNum);
    } catch (e) {
      showToast("load data error", "fail");
      if (pageNum === 1) {
        setHasMore(false);
      }
    }
  };

  const reload = () => {
    setList([]);
    setPageNum(0);
    setHasMore(false);
    loadMore(0, true);
  };

  return (
    <div className={styles.mainPage + " mainPage common-list"}>
      {/* <Swiper className={styles.bannerSwiper} indicatorProps={{
            color: 'white'
        }} defaultIndex={0} autoplay={true}>
            {
                banners.map((banner, index) => {
                    return <Swiper.Item key={index}>
                        <img src={banner} className={styles.bannerImg}/>
                    </Swiper.Item>
                })
            }
        </Swiper> */}
      {/* <div className={styles.title}>
            <div className={styles.titleLeft}>
                <img className={styles.titleLogo} src={logoImg} alt=""/>
                <span className={styles.titleText}>{t('Main.全年計劃')}</span>

            </div>
            <div className={styles.titleRight}>{t('Main.查看更多')}<RightOutline/></div>
        </div> */}
      <Swiper
        className={styles.bannerSwiper2}
        indicatorProps={
          {
            //   color: "white",
          }
        }
        defaultIndex={0}
        loop={true}
        autoplay={true}
      >
        {banners.map((banner, index) => {
          return (
            <Swiper.Item key={index}>
              <div className={styles.banner2Item}>
                <img src={banner.pic_url} className={styles.bannerImg} />
                <div className={styles.title}>
                  <div className={styles.titleLeft}>
                    <img className={styles.titleLogo} src={logoImg} alt="" />
                    <span className={styles.titleText}>
                      {/* {t("Main.全年計劃")} */}
                      {banner.title}
                    </span>
                  </div>
                  {banner.can_redirect && (
                    <div
                      className={styles.titleRight}
                      onClick={() => {
                        if (banner.open_new_tab) {
                          // 新打开跳转url
                          window.open(banner.redirect_url);
                        } else {
                          //   nav(banner.redirect_url);
                          window.location.replace(banner.redirect_url);
                        }
                      }}
                    >
                      {t("Main.查看更多")}
                      <RightOutline />
                    </div>
                  )}
                </div>
                <div className={styles.bannerText}>{banner.subtitle}</div>
              </div>
            </Swiper.Item>
          );
        })}
      </Swiper>
      <div className={"list-wrapper"} ref={listRef}>
        <div className="list">
          {list.map((item, index) => {
            if (item.display_style === "LA-01") {
              return <LA01 key={item.product_id} item={item}></LA01>;
            } else if (item.display_style === "LB-01") {
              return <LB01 key={item.product_id} item={item}></LB01>;
            }
          })}
        </div>
        <InfiniteScroll
          loadMore={() => loadMore(pageNum + 1)}
          hasMore={hasMore}
        />
      </div>
    </div>
  );
}
