import React, {ReactNode, useRef, useState} from "react";
import {Toast} from "antd-mobile";
import {ToastHandler} from "antd-mobile/es/components/toast";
import {getUser as getStorageUser, IUser} from "../utils/user";

const AppContext = React.createContext<{
    loading: boolean
    setLoading: (loading: boolean) => void
    startLoading: () => void
    closeLoading: () => void
    user: IUser | undefined,
    getUser: () => IUser | undefined
    setUser: (user: IUser | undefined) => void
} | undefined>(undefined)
AppContext.displayName = 'AppContext'

export const AppProvider = ({children}: { children: ReactNode }) => {
    const [loading, setLoading] = useState(false)

    const handler = useRef<ToastHandler>()

    const [user, setUser] = useState(getStorageUser())

    const getUser = () => {
        return user
    }

    const startLoading = (content?: string) => {
        handler.current = Toast.show({
            icon: 'loading',
            content: content,
            duration: 0,
            maskClickable: false
        })
    }
    const closeLoading = () => {
        if (handler.current) {
            handler.current?.close()
        }
    }


    return (<AppContext.Provider children={children}
                                 value={{loading, setLoading, startLoading, closeLoading, user, setUser, getUser}}/>)
}

export const useApp = () => {
    const context = React.useContext(AppContext)
    if (!context) {
        throw new Error('useApp 必须在AppProvider中使用')
    }
    return context
}