import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {HashRouter, BrowserRouter} from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AppProvider, useApp} from "./context/app-context";
import {AliveScope} from 'react-activation'
// 多语言
import {initI18n} from './utils/i18n'
import {ConfigProvider} from "antd-mobile";
import enUS from 'antd-mobile/es/locales/en-US'
import zhCN from 'antd-mobile/es/locales/zh-CN'
import {getUser} from "./utils/user";

// function resetHeight(){
//     // reset the body height to that of the inner browser
//     document.body.style.height = window.innerHeight +"px";
// }
// resetHeight();

const locales: Record<string, any> = {
    cn: zhCN,
    en: enUS
}

const init = async () => {
    await initI18n()
    const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
    );
    const lang = (getUser()?.lang || 'cn').toLowerCase()
    root.render(
        // <React.StrictMode>
        <ConfigProvider locale={locales[lang]}>
            <BrowserRouter>

                    <AppProvider>
                        <AliveScope>
                            <App/>
                        </AliveScope>
                    </AppProvider>

            </BrowserRouter>
        </ConfigProvider>
        // </React.StrictMode>
    );
}

init();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
