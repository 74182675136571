import TagManager from 'react-gtm-module'
import {GoogleGtmData} from "../config";

let inited = false
export const initTagManager = () => {
    if (inited) return
    TagManager.initialize({
        gtmId: GoogleGtmData.gtmId,
    })
    inited = true
}

export const updatePageView = (options: {
    path: string, title?: string
}) => {
    TagManager.dataLayer({
        dataLayer: {
            event: 'pageview',
            path: options.path,
            title: options.title
        }
    })
}