import React, {useState, useEffect} from 'react';
import loginImg from '../../assets/login_bg.svg'
import accountImg from '../../assets/account.svg'
import paswordImg from '../../assets/password.svg'
import './login.css'
import {Button, Input, Toast} from 'antd-mobile'
import {loginApi, permissionApi} from "../../api/auth";
import {useApp} from "../../context/app-context";
import {useNavigate} from "react-router";
import {setToken} from "../../utils/token";
import {setUser as setStorageUser} from "../../utils/user";
import {useTranslation} from "react-i18next";

export default function Login() {

    const [form, setForm] = useState({
        login_name: 'bunsir',
        password: 'bun9413'
    })

    const { t } = useTranslation()

    const navigate = useNavigate()
    const {startLoading, closeLoading, setUser} = useApp()
    const handleLogin = async () => {
        if (!form.login_name || !form.password) return

        try {
            startLoading()
            // login
            let res = await loginApi(form)
            // set token
            setToken(res.data.x_token)
            // permission request
            let permissionRes = await permissionApi(res.data.x_token)
            // set localstorage user
            setStorageUser(permissionRes.data)
            // set context user
            setUser(permissionRes.data)
            // 跳转到首页
            navigate('/')
        } catch (e: any) {
            const message = e.response.data.detail || e.message
            Toast.show({
                icon: 'fail',
                content: message
            })
            console.error(e)
        } finally {
            closeLoading()
        }

    }

    useEffect(() => {
        setStorageUser(undefined)
        setUser(undefined)
        setToken('')
    }, [])


    return <div className="login-page">
        <img className={"loginBg"} src={loginImg} alt=""/>
        <div className={"loginForm"}>
            <div className={"item"}>
                <img className={"icon"} src={accountImg} alt=""/>
                <Input value={form.login_name} onChange={value => setForm({
                    login_name: value,
                    password: form.password
                })} className={"input"} placeholder={"请输入用户名"}/>
            </div>
            <div className={"item"}>
                <img className={"icon"} src={paswordImg} alt=""/>
                <Input value={form.password} onChange={value => setForm({
                    login_name: form.login_name,
                    password: value
                })} className={"input"} placeholder={"请输入密码"} type={"password"}/>
            </div>
            <Button className={"loginBtn"} onClick={handleLogin} color={"primary"}
                    block>{t('Login.loginBtnText')}</Button>
        </div>
    </div>
}